import React, { useState } from 'react';
import HomeSlider from '../HomeSlider/HomeSlider';
import AboudUsIntro from '../AboudUsIntro/AboudUsIntro';
import Products from '../Products/Products';

const Home = (props) => {

    const [slides] = useState([
        {
            city: '',
            country: '',
            img: '/slider1.jpg',
        }
    ]);

    return (
        <div>
            <HomeSlider slides={slides} />
            <Products />
            <AboudUsIntro />
            {/* <GoalIntro /> */}
        </div>
    );
}

export default Home;