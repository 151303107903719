import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { Shimmer } from '@fluentui/react';

const FieldText = (props) => {

    const [value, setValue] = useState();

    useEffect(() => {
        let tValue = null;
        if (props.defaultValue) {
            tValue = props.defaultValue;
        }
        setValue(tValue);
    }, [props.defaultValue]);

    const onChanged = (pValue) => {
        setValue(pValue?.target.value);
        if (props.onChanged) {
            props.onChanged(pValue?.target.value, "", props.keyfield);
        }
    }

    return (
        props.loading ? (
            <div>
                <Shimmer />
                <Shimmer />
            </div>
        ) : (
            <div>
                <div style={{ fontWeight: 400, fontSize: 16, color: '#212121', marginBottom: 10 }}>{props.title}</div>
                <Input placeholder={props.placeholder} disabled={props.disable ? true : false} onChange={onChanged} value={value} />
            </div>
        )
    );
}

export default FieldText;