import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { firebaseApp } from '../../../../API/firebaseApp';
import { getDatabase, ref, push, get, set } from "firebase/database";
import { message } from 'antd';
import ButtonDefault from '../../../../Components/Base/ButtonDefault/ButtonDefault';
import FieldCheck from '../../../../Components/Base/FieldCheck/FieldCheck';
import FieldText from '../../../../Components/Base/FieldText/FieldText';
import FieldTextArea from '../../../../Components/Base/FieldTextArea/FieldTextArea';
import LoadSpin from '../../../../Components/Base/LoadSpin/LoadSpin';
import PanelBase from '../../../../Components/Base/PanelBase/PanelBase';

const CategoryNew = (props) => {

    const [initData, setInitData] = useState({
        name: null,
        active: true,
        created: moment().format("YYYY.MM.DD HH:mm:ss")
    });
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [checkRule, setCheckRule] = useState(false);

    const getData = async () => {
        let tData = { ...initData };
        setLoading(true);
        if (props.id) {
            await get(ref(getDatabase(firebaseApp), "category/" + props.id)).then(response => {
                let resp = response.toJSON();
                if (resp) {
                    tData = resp;
                }
            });
        }
        setLoading(false);

        setData(tData);
    }

    useEffect(() => {
        getData();
    }, [props.id]);

    const fieldOnChanged = async (key, text, field, fieldname) => {
        if (field) {
            let tData = { ...data };
            tData[field] = key;
            if (fieldname) {
                tData[fieldname] = text;
            }

            setData(tData);
        }
    }

    const onSave = async () => {

        setCheckRule(true);
        let success = false;

        let vRule = true;

        if (!data.name) {
            vRule = false;
        }

        if (vRule) {
            setLoadingSave(true);
            if (props.id) {
                await set(ref(getDatabase(firebaseApp), "category/" + props.id), data).then(response => {
                    success = true;
                    message.success("Амжилттай.");
                });
            } else {
                let tData = { ...data };
                tData.created = moment().format("YYYY.MM.DD HH:mm:ss");
                await push(ref(getDatabase(firebaseApp), "category"), tData).then(response => {
                    success = true;
                    message.success("Амжилттай.");
                });
            }
            setLoadingSave(false);

        }
        if (success) {
            onDismiss();
            if (props.onAfterSave) {
                props.onAfterSave();
            }
        }
    }

    const onDismiss = () => {
        setCheckRule(false);
        setData({ ...initData });
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    return (
        <div>
            <PanelBase
                isOpen={props.isOpen}
                onDismiss={onDismiss}
                headerText={props.id ? "Категори засах" : "Категори нэмэх"}
                buttons={(
                    <div>
                        {loadingSave ? (<LoadSpin />) : (<ButtonDefault onClick={onSave} text="Хадгалах" />)}
                    </div>
                )}
            >
                <div style={{ marginTop: 13 }}>
                    <FieldText required loading={loading} title="Категорийн нэр" onChanged={fieldOnChanged} keyfield='name' defaultValue={data.name} checkreq={checkRule} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldTextArea loading={loading} title="Категорийн тайлбар" onChanged={fieldOnChanged} keyfield='description' defaultValue={data.description} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldCheck loading={loading} title="Төлөв" onChanged={fieldOnChanged} keyfield='active' defaultValue={data.active} />
                </div>
            </PanelBase>
        </div>
    );
}

export default CategoryNew;