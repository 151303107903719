import React, { useEffect, useState } from 'react';
import { ReactComponent as Reduce } from '../../Svg/reduce.svg';
import { ReactComponent as Add } from '../../Svg/add.svg';

const ButtonAddReduce = (props) => {

    const [qty, setQty] = useState(1);

    useEffect(() => {
        let tQty = 1;
        if (props.value) {
            tQty = props.value;
        }
        setQty(tQty);
    }, [props.value]);

    const styles = {
        button: {
            height: 40,
            width: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
        }
    };

    const onReduce = () => {
        let tQty = qty;
        if (tQty > 1) {
            tQty -= 1;
        }
        setQty(tQty);
        onChange(tQty);
    }

    const onPlus = () => {
        let tQty = qty;
        tQty += 1;
        setQty(tQty);
        onChange(tQty);
    }

    const onChange = (pQty) => {
        if (props.onChange) {
            props.onChange(pQty);
        }
    }

    return (
        <div style={{ width: props.min ? 100 : 120, height: 40, border: '1px solid #BFBFBF', borderRadius: 24, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ ...styles.button }} onClick={onReduce}>
                <Reduce />
            </div>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {qty}
            </div>
            <div style={{ ...styles.button }} onClick={onPlus}>
                <Add />
            </div>
        </div>
    );
}

export default ButtonAddReduce;