import React from 'react';

const OrderIcon = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.7498 2C20.9925 2 21.9998 3.00736 21.9998 4.25V9.71196C21.9998 10.5738 21.6575 11.4003 21.0482 12.0098L12.5472 20.5129C11.2777 21.7798 9.22195 21.7807 7.95079 20.5143L3.48909 16.0592C2.21862 14.7913 2.21699 12.7334 3.48531 11.4632L11.985 2.95334C12.5946 2.34297 13.4218 2 14.2845 2H19.7498ZM19.7498 3.5H14.2845C13.82 3.5 13.3745 3.68467 13.0463 4.01333L4.53412 12.5358C3.86389 13.2207 3.86898 14.3191 4.54884 14.9977L9.01006 19.4522C9.69493 20.1345 10.8033 20.134 11.487 19.4518L19.9874 10.9492C20.3155 10.6211 20.4998 10.176 20.4998 9.71196V4.25C20.4998 3.83579 20.164 3.5 19.7498 3.5ZM16.9998 5.50218C17.8282 5.50218 18.4998 6.17374 18.4998 7.00216C18.4998 7.83057 17.8282 8.50213 16.9998 8.50213C16.1714 8.50213 15.4998 7.83057 15.4998 7.00216C15.4998 6.17374 16.1714 5.50218 16.9998 5.50218Z" fill={props.active ? "#F8A50C" : "#FFFFFF"} />
        </svg>
    );
}

export default OrderIcon;