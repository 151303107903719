import React, { useState, useEffect, useContext } from 'react';
import { get, getDatabase, ref, query, orderByChild, equalTo, set, push } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { firebaseApp } from '../../API/firebaseApp';
import Title from '../../Components/Title/Title';
import { addAddress, checkUserAuth, delCart, getAddress, getCart, updateCart } from '../../Const/SharedFunc';
import TitleMid from '../../Components/TitleMid/TitleMid';
import { Radio, Space, List, Row, Col, Divider, message, Modal } from 'antd';
import ButtonBase from '../../Components/ButtonBase/ButtonBase';
import { ReactComponent as ShopIcon } from '../../Svg/shop.svg';
import ListHeader from '../../Components/ListHeader/ListHeader';
import ButtonAddReduce from '../../Components/ButtonAddReduce/ButtonAddReduce';
import { ReactComponent as DeleteIcon } from '../../Svg/delete.svg';
import { CartContext } from '../../Context/CartContext';
import moment from 'moment';
import Number from '../../Components/Base/Number/Number';
import LoadSpin from '../../Components/Base/LoadSpin/LoadSpin';
import noImage from '../../Images/noImage.png';
import FieldTextArea from '../../Components/FieldTextArea/FieldTextArea';
import UserAgreement from '../UserAgreement/UserAgreement';
import UserDtl from '../UserDtl/UserDtl';

const Cart = (props) => {

    const navigate = useNavigate();
    const [initData] = useState({
        delivery: 0,
        totalprice: 0
    });
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingOrder, setLoadingOrder] = useState(false);
    const { cart, setCart, setCartPrev } = useContext(CartContext);
    const [showAgreement, setShowAgreement] = useState(false);
    const [loadingAgreement, setLoadingAgreement] = useState(false);
    const [showUserDtl, setShowUserDtl] = useState(false);

    const getData = async () => {
        let tData = { ...initData };

        let tAddress = getAddress();
        if (tAddress) {
            if (tAddress?.delivery) {
                tData.delivery = tAddress?.delivery;
            }
            if (tAddress?.address) {
                tData.address = tAddress?.address;
            }
        }

        tData.item = getCart();

        let tTotalItems = tData.item?.map(r => parseInt(r.qty) * parseFloat(r.price));
        tData.totalprice = parseFloat(parseFloat(tTotalItems.reduce((a, b) => a + b, 0)).toFixed(2));

        setData(tData);
        setCartPrev(false);
    }

    const fieldOnChanged = async (key, text, field, fieldname) => {
        if (field) {
            let tData = { ...data };
            tData[field] = key;
            if (fieldname) {
                tData[fieldname] = text;
            }
            setData(tData);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const onDel = async (pItemId) => {
        if (pItemId) {
            delCart(pItemId);
            await getData();
            await refreshCart();
        }
    }

    const onDelAll = async () => {
        if (data?.item?.length > 0) {
            localStorage.removeItem("cart");
            await getData();
            await refreshCart();
        }
    }

    const refreshCart = async () => {
        let tCart = getCart();
        setCart(tCart);
    }

    const onChangeQty = async (pItemid, pQty) => {
        updateCart(pItemid, pQty);
        await refreshCart();
        await getData();
    }

    const onOrder = async () => {

        let tData = { ...data };

        const tUserAuth = checkUserAuth();

        if (tUserAuth?.uid) {
            if (localStorage.getItem("useragreement")) {

                try {
                    setLoadingOrder(true);

                    let tUserData = {};
                    await get(query(ref(getDatabase(firebaseApp), "user"), orderByChild("uid"), equalTo(tUserAuth?.uid))).then(response => {
                        let resp = response.toJSON();
                        if (resp) {
                            Object.keys(resp)?.map(key => {
                                tUserData = resp[key];
                                return true;
                            });
                        }
                    });

                    if (tUserData?.phone) {
                        if (data?.item?.length > 0) {
                            if (data.address) {
                                addAddress({
                                    delivery: tData?.delivery,
                                    address: tData?.address
                                });

                                let success = false;
                                let tOrderId = null;

                                let tUserAuth = checkUserAuth();

                                tData.created = moment().format("YYYY.MM.DD HH:mm:ss");
                                tData.uid = tUserAuth.uid;
                                tData.refno = moment().unix();
                                tData.status = 0;
                                tData.statusname = "Төлбөр хүлээгдэж буй";
                                tData.phone = tUserAuth?.phoneNumber ?? tUserData?.phone;
                                tData.email = tUserAuth?.email ?? null;
                                tData.displayname = tUserAuth?.displayName ?? null;

                                await push(ref(getDatabase(firebaseApp), "order"), tData).then(response => {
                                    success = true;
                                    tOrderId = response.key;
                                }).catch(respnose => {
                                    setLoadingOrder(false);
                                });
                                await onDelAll();
                                await refreshCart();


                                if (success) {
                                    navigate("/userinfo/orderinfo?orderid=" + tOrderId + "&payment=true");
                                }
                            } else {
                                if (!data.address) {
                                    message.warning("Хаяг оруулна уу.");
                                }
                            }

                        } else {
                            message.warning("Захиалга үүсгэх бараа байхгүй байна.");
                        }
                    } else {
                        setShowUserDtl(true);
                    }
                } finally {
                    setLoadingOrder(false);
                }
            } else {
                setShowAgreement(true);
            }
        } else {
            addAddress({
                delivery: tData?.delivery,
                address: tData?.address
            });
            navigate("/login?callback=cart");
        }
    }

    const onApproveAgreement = async () => {
        const tUserAuth = checkUserAuth();
        if (tUserAuth?.uid) {
            try {
                setLoadingAgreement(true);
                let tDataParam = {
                    uid: tUserAuth.uid ?? null,
                    created: moment().format("YYYY.MM.DD HH:mm:ss"),
                    phone: tUserAuth?.phoneNumber ?? null,
                    email: tUserAuth?.email ?? null,
                    displayname: tUserAuth?.displayName ?? null
                };
                await push(ref(getDatabase(firebaseApp), "useragreement"), tDataParam).then(response => {
                    message.success("Амжилттай");
                });
                localStorage.setItem("useragreement", moment().format("YYYY.MM.DD HH:mm:ss"));
                setShowAgreement(false);
            } finally {
                setLoadingAgreement(false);
            }
        } else {
            navigate("/login?callback=cart");
        }
    }

    return (
        <div className='mainPadding' style={{ paddingTop: 36, paddingBottom: 36 }}>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 17 }} xxl={{ span: 16 }}>
                    <div>
                        <Title value={"Миний сагс"} />
                        <div style={{ marginTop: 45 }}>
                            <Row gutter={[16, 16]} style={{ width: '100%' }}>
                                <Col xs={{ span: 2 }} sm={{ span: 2 }} md={{ span: 1 }} lg={{ span: 1 }} xl={{ span: 1 }} xxl={{ span: 1 }}></Col>
                                <Col xs={{ span: 6 }} sm={{ span: 4 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }} xxl={{ span: 3 }}></Col>
                                <Col xs={{ span: 16 }} sm={{ span: 12 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 9 }} xxl={{ span: 10 }}>
                                    <ListHeader value={"Бүтээгдэхүүн"} />
                                </Col>
                                <Col xs={{ span: 0 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 3 }} xl={{ span: 4 }} xxl={{ span: 3 }}>
                                    <ListHeader value={"Үнэ"} />
                                </Col>
                                <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 4 }} lg={{ span: 3 }} xl={{ span: 3 }} xxl={{ span: 3 }}>
                                    <div style={{ textAlign: 'right' }}>
                                        <ListHeader value={"Тоо, ширхэг"} />
                                    </div>
                                </Col>
                                <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 0 }} lg={{ span: 4 }} xl={{ span: 4 }} xxl={{ span: 4 }}>
                                    <div style={{ textAlign: 'right' }}>
                                        <ListHeader value={"Нийт"} />
                                    </div>
                                </Col>
                            </Row>
                            <Divider />
                            <List
                                itemLayout='horizontal'
                                dataSource={data?.item}
                                locale={{
                                    emptyText: "Мэдээлэл байхгүй"
                                }}
                                renderItem={(item, index) => {
                                    return (
                                        <List.Item>
                                            <Row gutter={[16, 16]} align='middle' style={{ width: '100%', marginTop: index == 0 ? 0 : 16, marginBottom: (index + 1 == data?.item?.length) ? 0 : 16 }}>
                                                <Col xs={{ span: 2 }} sm={{ span: 2 }} md={{ span: 1 }} lg={{ span: 1 }} xl={{ span: 1 }} xxl={{ span: 1 }}>
                                                    <div style={{ cursor: 'pointer' }} onClick={() => onDel(item.id)}>
                                                        <DeleteIcon />
                                                    </div>
                                                </Col>
                                                <Col xs={{ span: 6 }} sm={{ span: 4 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }} xxl={{ span: 3 }}>
                                                    {item.img ? (
                                                        <img style={{ width: 60, height: 60, border: '1px solid #E7E7E7' }} src={item.img} alt="" />
                                                    ) : (
                                                        <img style={{ width: 60, height: 60, border: '1px solid #E7E7E7' }} src={noImage} alt="" />
                                                    )}
                                                </Col>
                                                <Col xs={{ span: 16 }} sm={{ span: 12 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 9 }} xxl={{ span: 10 }}>
                                                    <div style={{ fontWeight: 400, fontSize: 14, color: '#212121' }}>
                                                        {item.name} {item.producttypename && ("/" + item.producttypename + "," + item?.productcolorname + "/")}
                                                    </div>
                                                </Col>
                                                <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 3 }} xl={{ span: 4 }} xxl={{ span: 3 }}>
                                                    <div style={{ fontWeight: 400, fontSize: 14, color: '#212121' }}>
                                                        <Number value={item.price} symbol="₮" />
                                                    </div>
                                                </Col>

                                                <Col xs={{ span: 8 }} sm={{ span: 6, offset: 12 }} md={{ span: 6, offset: 0 }} lg={{ span: 3 }} xl={{ span: 3 }} xxl={{ span: 3 }}>
                                                    <div style={{ marginTop: -8 }}>
                                                        <ButtonAddReduce onChange={(pValue) => onChangeQty(item.id, pValue)} min value={item.qty} />
                                                    </div>
                                                </Col>
                                                <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 6, offset: 16 }} lg={{ span: 4, offset: 0 }} xl={{ span: 4, offset: 0 }} xxl={{ span: 4, offset: 0 }}>
                                                    <div style={{ fontWeight: 600, fontSize: 14, color: '#212121' }}>
                                                        <Number value={parseFloat((parseFloat(item.price) * parseInt(item.qty)).toFixed(2))} symbol="₮" />
                                                    </div>
                                                </Col>

                                            </Row>
                                        </List.Item>
                                    );
                                }}
                            />
                            <Divider />
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <ButtonBase onClick={onDelAll} color="#212121" text={"Сагс хоослох"} />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 7 }} xxl={{ span: 8 }}>
                    <div style={{ background: '#FFFFFF', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)', padding: 16 }}>
                        <Title value={"Захиалгын мэдээлэл"} />
                        <div style={{
                            marginTop: 24,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px dashed #D3D3D3',
                            paddingBottom: 16
                        }}>
                            <TitleMid value={"Бүтээгдэхүүний дүн"} />
                            <div style={{ fontWeight: 500, fontSize: 16, color: '#212121' }}>
                                <Number value={data?.totalprice} symbol="₮" />
                            </div>
                        </div>
                        <div style={{ marginTop: 16 }}>
                            <TitleMid value={"Хаяг"} />
                        </div>
                        <div style={{ borderBottom: '1px dashed #D3D3D3', paddingBottom: 16 }}>
                            <div style={{ marginTop: 16 }}>
                                <FieldTextArea placeholder={"Хаяг"} loading={loading} onChanged={fieldOnChanged} keyfield='address' defaultValue={data.address} />
                            </div>
                        </div>
                        <div style={{ marginTop: 16 }}>
                            <Radio.Group onChange={(pValue) => fieldOnChanged(pValue.target.value, "", "delivery")} value={data?.delivery}>
                                <Space direction="vertical">
                                    <Radio value={0}>Салбараас очиж авах: 0₮</Radio>
                                    <Radio value={7000}>Хүргүүлэх: 7,000₮</Radio>
                                </Space>
                            </Radio.Group>
                        </div>
                        <div style={{
                            marginTop: 32,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <TitleMid value={"Нийт дүн"} />
                            <div style={{ fontWeight: 500, fontSize: 20, color: '#212121' }}>
                                <Number value={data?.totalprice + data?.delivery} symbol="₮" />
                            </div>
                        </div>
                        <div style={{ marginTop: 20 }}>
                            {loadingOrder ? (
                                <LoadSpin />
                            ) : (
                                <ButtonBase onClick={onOrder} icon={<ShopIcon />} text={"Захиалга хийх"} />
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
            {showAgreement ? (
                <Modal width={"70%"} title="Үйлчилгээний нөхцөл" open={showAgreement} onOk={() => { }} onCancel={() => { setShowAgreement(false); }} footer={null}>
                    <div>
                        <UserAgreement />
                    </div>
                    <div style={{ marginTop: 16, display: 'flex', justifyContent: 'flex-end' }}>
                        {loadingAgreement ? (<LoadSpin />) : (
                            <ButtonBase onClick={onApproveAgreement} text={"Үйлчилгээний нөхцөл зөвшөөрөх"} />
                        )}
                    </div>
                </Modal>
            ) : null}
            {showUserDtl ? (
                <Modal width={"70%"} title="" open={showUserDtl} onOk={() => { }} onCancel={() => { setShowUserDtl(false); }} footer={null}>
                    <div>
                        <UserDtl warning afterSave={() => { setShowUserDtl(false); }} />
                    </div>
                </Modal>
            ) : null}
        </div>
    );
}

export default Cart;