import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as Logo } from '../../Svg/logo.svg';
import { ReactComponent as Cart } from '../../Svg/cart.svg';
import { ReactComponent as User } from '../../Svg/user.svg';
import { ReactComponent as VerticalDivider } from '../../Svg/verticalDivider.svg';
import HeaderText from '../../Components/HeaderText/HeaderText';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Menu, Popover } from 'antd';
import { CartContext } from '../../Context/CartContext';
import CartCounter from '../../Components/CartCounter/CartCounter';
import { ReactComponent as MenuIcon } from '../../Svg/menu.svg';
import { getCart } from '../../Const/SharedFunc';
import CartPrev from '../../Components/CartPrev/CartPrev';

const Header = (props) => {

    const { cart, setCart, cartPrev, setCartPrev } = useContext(CartContext);
    const navigate = useNavigate();

    const getData = () => {
        let tCart = getCart();
        setCart(tCart);
    }

    useEffect(() => {
        getData();
    }, []);

    const onNavigate = (p_value) => {
        navigate("/" + p_value);
    }

    const items = [
        {
            key: '1',
            label: <div onClick={() => onNavigate("")}>Нүүр хуудас</div>
        },
        {
            key: '2',
            label: <div onClick={() => onNavigate("news")}>Заавар зөвлөгөө</div>
        }, {
            key: '3',
            label: <div onClick={() => onNavigate("products")}>Бүтээгдэхүүн</div>
        }
    ];

    return (
        <div>
            <div className='mainPadding' style={{ height: 64, boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className='smallMenu' style={{ display: 'flex', alignItems: 'center' }}>
                    <Dropdown menu={{ items }} trigger={['click']}>
                        <div>
                            <MenuIcon />
                        </div>
                    </Dropdown>
                </div>
                <div onClick={() => onNavigate("")} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', height: 34 }}>
                    <Logo />
                </div>
                <div className='mainMenu' style={{ display: 'flex', alignItems: 'center' }}>
                    <HeaderText onClick={() => onNavigate("")}>
                        Нүүр хуудас
                    </HeaderText>
                    <VerticalDivider />
                    <HeaderText onClick={() => onNavigate("products")}>
                        Бүтээгдэхүүн
                    </HeaderText>
                    <VerticalDivider />
                    <HeaderText onClick={() => onNavigate("news")}>
                        Заавар зөвлөгөө
                    </HeaderText>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ height: 24, cursor: 'pointer' }} onClick={() => onNavigate("cart")}>
                        <CartCounter data={cart} />
                        <Popover placement='bottomLeft' trigger="click" open={cartPrev} onOpenChange={() => { setCartPrev(false); }} content={<CartPrev />}>
                            <Cart />
                        </Popover>
                    </div>
                    <div style={{ height: 24, marginLeft: 24, cursor: 'pointer' }} onClick={() => onNavigate("login")}>
                        <User />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Header;