import React, { useState, useEffect } from 'react';
import { get, ref, getDatabase } from 'firebase/database';
import { firebaseApp } from '../../../API/firebaseApp';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../../Components/Base/PageHeader/PageHeader';
import GridDefaultShimmer from '../../../Components/Base/GridDefaultShimmer/GridDefaultShimmer';
import ColEdit from '../../../Components/Base/ColEdit/ColEdit';

const User = (props) => {

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [oData, setOData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [columns, setColumns] = useState([
        {
            name: 'Код',
            fieldName: 'uid',
            onRender: (item) => {
                return (
                    <ColEdit onClick={() => onEdit(item.id)}>{item.uid}</ColEdit>
                );
            },
            minColSize: 'small'
        }, {
            name: 'Овог',
            fieldName: 'lastname',
            minColSize: 'small'
        }, {
            name: 'Нэр',
            fieldName: 'firstname',
            minColSize: 'small'
        }, {
            name: 'Утас',
            fieldName: 'phone',
            minColSize: 'small'
        }, {
            name: 'И-мэйл хаяг',
            fieldName: 'email',
            minColSize: 'small'
        },
    ]);

    const getData = async () => {
        let tData = [];

        setLoading(true);
        await get(ref(getDatabase(firebaseApp), "user")).then(response => {
            let resp = response.toJSON();
            if (resp) {
                Object.keys(resp)?.map(key => {
                    tData.push({ id: key, ...resp[key] });
                });
            }
        }).catch(response => {
            setLoading(true);
        });
        setLoading(false);

        tData.sort((a, b) => {
            if (a.created > b.created) {
                return -1;
            }
            if (a.created < b.created) {
                return 1;
            }
            return 0;
        });

        setData(tData);
        setOData(tData);
    }

    useEffect(() => {
        getData();
    }, []);

    const onSearch = (p_data) => {
        setData(p_data);
    }

    const onEdit = (pId) => {
        navigate("/admin/user/" + pId);
    }

    return (
        <div>
            <PageHeader
                title="Хэрэглэгч"
                dataCount={data?.length}
                columns={columns}
                data={oData}
                filteredData={data}
                onSearch={onSearch}
            />
            <GridDefaultShimmer
                data={data}
                columns={columns}
                loading={loading}
            />
        </div>
    );
}

export default User;