// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyD__jwuItVVRUvLCFIK2FnMFLyfJgWeM_M",
    authDomain: "uuruu-82c1d.firebaseapp.com",
    databaseURL: "https://uuruu-82c1d-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "uuruu-82c1d",
    storageBucket: "uuruu-82c1d.appspot.com",
    messagingSenderId: "857711232370",
    appId: "1:857711232370:web:06c5b28a036c38956389eb"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);