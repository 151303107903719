import React, { useState, useEffect } from 'react';
import { Dropdown } from '@fluentui/react';
import { Color } from '../../const/Const';

const GridFilterField = (props) => {

    const [value, setValue] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        let tData = [];
        if (props.data?.length > 0) {
            tData = props.data;
            if (props.parentkey) {
                if (props.parentvalue?.length > 0) {
                    let tParentValue = [...props.parentvalue].filter(r => r.fieldname == props.parentkey)?.map(r => r.value);
                    tData = tData.filter(r => tParentValue?.includes(r.parentid));
                } else {
                    tData = [];
                }
            }
        }
        setData(tData);
    }, [props.data, props.parentvalue]);

    useEffect(() => {
        let tValue = [];
        if (props.selectedKeys?.length > 0) {
            tValue = props.selectedKeys;
        }
        setValue(tValue);
    }, [])

    const onChanged = (p_value) => {
        let tValue = [...value];
        if (p_value.selected) {
            tValue.push(p_value.key);
        } else {
            let tIndex = tValue.indexOf(p_value.key);
            if (tIndex >= 0) {
                tValue.splice(tIndex, 1);
            }
        }
        setValue(tValue);

        let tRValue = [];
        tValue?.map(r => {
            tRValue.push({
                fieldname: props.keyfield,
                value: r
            });
        });
        props.onChanged(tRValue, props.keyfield);
    }

    return (
        <div>
            <Dropdown
                styles={{
                    root: {
                        maxWidth: 180
                    },
                    title: {
                        border: '0px',
                        backgroundColor: Color.grayHeaderBack
                    },
                    callout: {
                        minWidth: 300
                    }
                }}
                disabled={data?.length > 0 ? false : true}
                multiSelect
                options={data}
                placeholder={props.namefield}
                onChanged={onChanged}
                selectedKeys={value}
            />
        </div>
    );
}

export default GridFilterField;