import React, { useState, useEffect, useContext, Fragment } from 'react';
import { Breadcrumb, Col, Row, message } from 'antd';
import NumberBase from '../../Components/NumberBase/NumberBase';
import ButtonAddReduce from '../../Components/ButtonAddReduce/ButtonAddReduce';
import ButtonCart from '../../Components/ButtonCart/ButtonCart';
import ProductImages from './ProductImages/ProductImages';
import { useNavigate, useParams } from 'react-router-dom';
import { get, getDatabase, ref } from 'firebase/database';
import { firebaseApp } from '../../API/firebaseApp';
import { CartContext } from '../../Context/CartContext';
import noImage from '../../Images/noImage.png';
import { getCart } from '../../Const/SharedFunc';
import LoadSpin from '../../Components/Base/LoadSpin/LoadSpin';
import FieldDropdown from '../../Components/FieldDropdown/FieldDropdown';

const ProductDtl = () => {

    const navigate = useNavigate();
    const params = useParams();

    const { setCart, setCartPrev } = useContext(CartContext);

    const [initData] = useState({ qty: 1 });
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [selImg, setSelImg] = useState();

    const getData = async () => {
        let tData = { ...initData };
        if (params.id) {
            setLoading(true);
            await get(ref(getDatabase(firebaseApp), "product/" + params.id)).then(response => {
                let resp = response.toJSON();
                if (resp) {
                    tData = { id: params.id, ...resp, qty: 1 };

                    let tSpecifications = [];
                    if (tData?.specifications) {
                        Object.keys(tData.specifications)?.map(key => {
                            tSpecifications.push({
                                text: tData.specifications[key].name,
                                value: tData.specifications[key].body
                            });
                        });
                    }
                    tData.specifications = tSpecifications;

                    let tImages = [];
                    if (tData?.images) {
                        Object.keys(tData.images)?.map(key => {
                            tImages.push(tData.images[key].img);
                        });
                    }
                    tData.images = tImages;

                    let tProductType = [];
                    if (tData?.producttype) {
                        Object.keys(tData.producttype)?.map(key => {
                            tProductType.push({ ...tData.producttype[key], key: tData.producttype[key]?.id, text: tData.producttype[key]?.name });
                        });
                    }
                    tData.producttype = tProductType;

                    let tProductColor = [];
                    if (tData?.productcolor) {
                        Object.keys(tData.productcolor)?.map(key => {
                            tProductColor.push({ ...tData.productcolor[key], key: tData.productcolor[key]?.id, text: tData.productcolor[key]?.name });
                        });
                    }
                    tData.productcolor = tProductColor;
                }
            });

            setLoading(false);
        }

        setData(tData);
    }

    useEffect(() => {
        getData();
    }, [params?.id]);

    const onBack = (pUrl) => {
        navigate(pUrl);
    }

    const fieldOnChanged = async (key, text, field, fieldname) => {
        if (field) {
            let tData = { ...data };
            tData[field] = key;
            if (fieldname) {
                tData[fieldname] = text;
            }
            setData(tData);
        }
    }

    const onCart = async () => {
        if (data?.price > 0) {
            let vRule = true;

            if (data?.productcolor?.length > 0 && !data?.productcolorid) {
                vRule = false;
            }

            if (data?.producttype?.length > 0 && !data?.producttypeid) {
                vRule = false;
            }

            if (vRule) {
                let tData = { ...data };
                let tCart = getCart();

                let tIndex = tCart?.map(r => r.id).indexOf(tData?.id);

                if (tIndex >= 0) {
                    tCart[tIndex].qty = parseInt(tCart[tIndex].qty) + parseInt(tData.qty);
                } else {
                    tCart.push({ ...tData });
                }

                setCart(tCart);
                localStorage.setItem("cart", JSON.stringify(tCart));

                tData.qty = 1;
                setData(tData);
                setCartPrev(true);
            } else {
                message.warning("Өнгө болон хэмжээ сонгоно уу.")
            }
        } else {
            message.warning("Сагсанд нэмэх боломжгүй байна.")
        }
    }

    if (loading) {
        return (
            <LoadSpin space />
        );
    }

    return (
        <div className='mainPadding' style={{ paddingTop: 20, paddingBottom: 120 }}>
            <div>
                <Breadcrumb items={[
                    { key: 1, title: <div style={{ cursor: 'pointer' }} onClick={() => onBack("/products")}>{"Бүтээгдэхүүн"}</div> },
                    { key: 2, title: <div>{data?.name}</div> }
                ]} />
            </div>
            <div style={{ marginTop: 20 }}>
                <Row gutter={[16, 16]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                        <div>
                            <div className='mainProdImage' style={{ background: '#FFFFFF' }}>
                                {selImg ?
                                    (<img className='mainProdImage' style={{ border: '1px solid #E7E7E7' }} src={selImg} alt="" />)
                                    : (data.img ? (
                                        <img className='mainProdImage' style={{ border: '1px solid #E7E7E7' }} src={data.img} alt="" />
                                    ) : (
                                        <img className='mainProdImage' style={{ border: '1px solid #E7E7E7' }} src={noImage} alt="" />
                                    ))}
                            </div>
                            <div style={{ marginTop: 24 }}>
                                {data?.images?.length > 0 ? (
                                    <ProductImages onChange={setSelImg} data={data.images} />
                                ) : null}
                            </div>
                        </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                        <div>
                            <div style={{ fontFamily: 'Montserrat', fontWeight: 700, fontSize: 20, color: '#212121' }}>
                                {data.name}
                            </div>
                            <div style={{ marginTop: 19, fontWeight: 300, fontSize: 14, color: '#000000' }}>
                                {data.description}
                            </div>
                            <div style={{ marginTop: 40, fontWeight: 400, fontSize: 12, color: '#767676' }}>
                                {"Үнэ"}
                            </div>
                            <div style={{ marginTop: 8 }}>
                                {data?.price > 0 ? (
                                    <NumberBase value={data.price} />
                                ) : (
                                    <div style={{ fontWeight: 600, fontSize: 32, color: '#212121' }}>
                                        -- ₮
                                    </div>
                                )}
                            </div>
                            {data?.productcolor?.length > 0 ? (
                                <div style={{ marginTop: 13, maxWidth: 280 }}>
                                    <FieldDropdown placeholder="Өнгө" loading={loading} onChanged={fieldOnChanged} keyfield='productcolorid' keyfieldname="productcolorname" defaultValue={data?.productcolorid} data={data?.productcolor ?? []} />
                                </div>
                            ) : null}
                            {data?.producttype?.length > 0 ? (
                                <div style={{ marginTop: 13, maxWidth: 280 }}>
                                    <FieldDropdown placeholder="Хэмжээ" loading={loading} onChanged={fieldOnChanged} keyfield='producttypeid' keyfieldname="producttypename" defaultValue={data?.producttypeid} data={data?.producttype ?? []} />
                                </div>
                            ) : null}
                            <div style={{ marginTop: 40, display: 'flex' }}>
                                <ButtonAddReduce onChange={(pValue) => fieldOnChanged(pValue, "", "qty")} value={data.qty} />
                                <div style={{ marginLeft: 20 }}>
                                    <ButtonCart onClick={onCart} />
                                </div>
                            </div>
                            {data?.expirydate ? (
                                <Fragment>
                                    <div style={{ marginTop: 40, fontFamily: 'Montserrat', fontWeight: 700, fontSize: 16, color: '#212121' }}>
                                        Дуусах хугацаа
                                    </div>
                                    <div style={{ marginTop: 8, fontSize: 14, color: '#000000' }}>
                                        {data?.expirydate}
                                    </div>
                                </Fragment>
                            ) : null}
                            {data?.specifications?.length > 0 ? (
                                <Fragment>
                                    <div style={{ marginTop: 40, fontFamily: 'Montserrat', fontWeight: 700, fontSize: 16, color: '#212121' }}>
                                        Үзүүлэлтүүд
                                    </div>
                                    <div style={{ marginTop: 16, maxWidth: 600 }}>
                                        {data?.specifications?.map((r, index) => {
                                            return (
                                                <div key={index} style={{ marginTop: 8, display: 'flex', justifyContent: 'space-between', fontWeight: 500, fontSize: 14, color: '#000000' }}>
                                                    <div>{r.text}</div>
                                                    <div style={{ textAlign: 'right' }}>{r.value}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Fragment>
                            ) : null}
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default ProductDtl;