import React, { useEffect, useState } from 'react';
import { Input } from 'antd';

const FieldNumber = (props) => {

    const [value, setValue] = useState();

    useEffect(() => {
        let tValue = null;
        if (props.value) {
            tValue = props.value;
        }
        setValue(tValue);
    }, [props.value]);

    const onChange = (pValue) => {
        setValue(pValue.target.value);
        if (props.onChange) {
            props.onChange(pValue.target.value, "", props.keyfield)
        }
    }

    return (
        <div>
            <div style={{ fontWeight: 400, fontSize: 16, color: '#212121', marginBottom: 10 }}>{props.title}</div>
            <Input type={"number"} onChange={onChange} value={value} />
        </div>
    );
}

export default FieldNumber;