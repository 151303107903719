import React from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';

const LoadSpin = (props) => {
    return (
        <div style={{ margin: props?.space ? 16 : 0 }}>
            <Spinner size={props?.small ? SpinnerSize.small : SpinnerSize.medium} />
        </div>
    );
}

export default LoadSpin;