import React from 'react';

const TimelineIcon = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.75257 17.9997H21.2525C21.6668 17.9997 22.0025 18.3355 22.0025 18.7497C22.0025 19.1294 21.7204 19.4432 21.3543 19.4928L21.2525 19.4997H2.75257C2.33835 19.4997 2.00257 19.1639 2.00257 18.7497C2.00257 18.37 2.28472 18.0562 2.6508 18.0065L2.75257 17.9997H21.2525H2.75257ZM2.75257 11.5027H21.2525C21.6668 11.5027 22.0025 11.8385 22.0025 12.2527C22.0025 12.6324 21.7204 12.9462 21.3543 12.9959L21.2525 13.0027H2.75257C2.33835 13.0027 2.00257 12.6669 2.00257 12.2527C2.00257 11.873 2.28472 11.5592 2.6508 11.5095L2.75257 11.5027H21.2525H2.75257ZM2.75171 5.00293H21.2517C21.6659 5.00293 22.0017 5.33872 22.0017 5.75293C22.0017 6.13263 21.7195 6.44642 21.3535 6.49608L21.2517 6.50293H2.75171C2.33749 6.50293 2.00171 6.16714 2.00171 5.75293C2.00171 5.37323 2.28386 5.05944 2.64994 5.00978L2.75171 5.00293H21.2517H2.75171Z" fill={props.active ? "#F8A50C" : "#FFFFFF"} />
        </svg>
    );
}

export default TimelineIcon;