import React from 'react';

const HeaderText = (props) => {
    return (
        <div style={{
            fontWeight: 500,
            fontSize: 14,
            color: '#212121',
            marginLeft: 16,
            marginRight: 16,
            cursor: 'pointer'
        }}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    );
}

export default HeaderText;