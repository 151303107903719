import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { ReactComponent as UploadIcon } from '../../../../Svg/upload.svg';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { firebaseApp } from '../../../../API/firebaseApp';
import { message } from 'antd';
import PageHeader from '../../../../Components/Base/PageHeader/PageHeader';
import GridDefaultShimmer from '../../../../Components/Base/GridDefaultShimmer/GridDefaultShimmer';
import ColEdit from '../../../../Components/Base/ColEdit/ColEdit';
import LoadSpin from '../../../../Components/Base/LoadSpin/LoadSpin';

const ProductImage = (props) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [id, setId] = useState();

    useEffect(() => {
        let tData = [];
        if (props.defaultValue?.length > 0) {
            tData = props.defaultValue;
        }
        setData(tData);
    }, [props.defaultValue]);

    const columns = [
        {
            name: 'Зургийн нэр',
            fieldName: 'name',
            minColSize: 'mid'
        }, {
            name: 'Огноо',
            fieldName: 'created'
        }, {
            name: 'Үйлдэл',
            fieldName: 'action',
            onRender: (item) => {
                return (
                    <ColEdit onClick={() => onDelete(item.id)}>Устгах</ColEdit>
                );
            }
        }
    ];

    const onAfterSave = (pData) => {
        let tData = [...data];
        if (pData?.id) {
            tData.push(pData);
        }
        if (props.onChanged) {
            props.onChanged(tData, null, props.keyfield, props.keyfieldname);
        }
    }

    const onDelete = (pId) => {
        let tData = [...data];
        if (pId) {
            let tIndex = tData?.map(r => r.id).indexOf(pId);
            if (tIndex >= 0) {
                tData.splice(tIndex, 1);
            }
        }
        if (props.onChanged) {
            props.onChanged(tData, null, props.keyfield, props.keyfieldname);
        }
    }

    const readUploadFile = async (e) => {
        e.preventDefault();
        let success = false;
        if (e.target.files?.length > 0) {

            let unixId = moment().unix().toString();
            let imageRef = ref(getStorage(firebaseApp), unixId);
            let downloadUrl = "";

            setLoadingUpload(true);
            await uploadBytes(imageRef, e.target.files[0]).then((snapshot) => {
                success = true;
                message.success("Амжилттай");
            });

            await getDownloadURL(imageRef).then(response => {
                downloadUrl = response;
            });

            onAfterSave({
                id: unixId,
                name: e.target.files[0].name,
                created: moment().format("YYYY.MM.DD HH:mm:ss"),
                img: downloadUrl
            });

            setLoadingUpload(false);
        }
    }

    return (
        <div>
            <PageHeader
                title={props.title}
                addButton={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {loadingUpload ? (
                            <LoadSpin />
                        ) : (
                            <div className='fileUpload'>
                                <label className='custom-file-upload'>
                                    <input
                                        type="file"
                                        name="upload"
                                        id="upload"
                                        accept="image/*"
                                        onChange={readUploadFile}
                                    />
                                    <UploadIcon />
                                    <div style={{ marginLeft: 8 }}>
                                        Зураг нэмэх
                                    </div>
                                </label>
                            </div>
                        )}
                    </div>
                }
                dataCount={data?.length}
            />
            <GridDefaultShimmer
                data={data}
                columns={columns}
                loading={loading}
            />
        </div>
    );
}

export default ProductImage;