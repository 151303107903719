import React, { useEffect, useState } from 'react';
import Title from '../../Components/Title/Title';
import { Row, Col, message } from 'antd';
import FieldText from '../../Components/FieldText/FieldText';
import FieldLabel from '../../Components/FieldLabel/FieldLabel';
import ButtonBase from '../../Components/ButtonBase/ButtonBase';
import { get, getDatabase, push, ref, set, query, orderByChild, equalTo } from 'firebase/database';
import { firebaseApp } from '../../API/firebaseApp';
import moment from 'moment';
import LoadSpin from '../../Components/Base/LoadSpin/LoadSpin';
import Warning from '../../Components/Base/Warning/Warning';

const UserDtl = (props) => {

    const [data, setData] = useState({});
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const getInitData = async () => {
        let tData = { ...data };
        if (localStorage.getItem("userAuth")) {
            let tUserAuth = JSON.parse(localStorage.getItem("userAuth"));
            tData.phone = tUserAuth?.phoneNumber ?? null;
            tData.uid = tUserAuth.uid;
        }

        if (tData.uid) {
            try {
                setLoading(true);
                await get(query(ref(getDatabase(firebaseApp), "user"), orderByChild("uid"), equalTo(tData.uid))).then(response => {
                    let resp = response.toJSON();
                    if (resp) {
                        Object.keys(resp)?.map(key => {
                            tData = { id: key, ...resp[key] };
                            return true;
                        });
                    }
                }).catch(response => {
                    setLoading(false);
                });
            } finally {
                setLoading(false);
            }

        }

        setData(tData);
        setEdit(false);
    }

    useEffect(() => {
        getInitData();
    }, []);

    const fieldOnChanged = async (key, text, field, fieldname) => {
        if (field) {
            let tData = { ...data };
            tData[field] = key;
            if (fieldname) {
                tData[fieldname] = text;
            }

            setData(tData);
            setEdit(true);
        }
    }

    const onSave = async () => {
        try {

            setLoadingSave(true);
            let tData = { ...data };

            if (tData?.phone) {
                if (tData?.id) {
                    await set(ref(getDatabase(firebaseApp), "user/" + tData.id), tData).then(response => {
                        message.success("Амжилттай");
                    });
                } else {
                    tData.created = moment().format("YYYY.MM.DD HH:mm:ss");
                    await push(ref(getDatabase(firebaseApp), "user"), tData).then(response => {
                        tData.id = response.key;
                        message.success("Амжилттай");
                    });
                }
                setData(tData);
                setEdit(false);
                if (props?.afterSave) {
                    props.afterSave();
                }
            } else {
                message.warning("Утасны дугаар оруулна уу");
            }

        } finally {
            setLoadingSave(false);
        }
    }

    return (
        <div>
            <Title value={"Хувийн мэдээлэл"} />
            {props?.warning ? (
                <Warning value="Утасны дугаар дутуу байгаа тул захиалга хийх боломжгүй байна. Утасны дугаар оруулна уу!" />
            ) : null}
            <Row style={{ marginTop: 24 }} gutter={[16, 16]}>
                <Col span={8}>
                    <FieldLabel value={"Овог"} />
                    <FieldText loading={loading} onChanged={fieldOnChanged} keyfield='lastname' defaultValue={data.lastname} />
                </Col>
                <Col span={8}>
                    <FieldLabel value={"Нэр"} />
                    <FieldText loading={loading} onChanged={fieldOnChanged} keyfield='firstname' defaultValue={data.firstname} />
                </Col>
                <Col span={8}>
                    <FieldLabel value={"Утасны дугаар"} />
                    <FieldText loading={loading} onChanged={fieldOnChanged} keyfield='phone' defaultValue={data.phone} />
                </Col>
                <Col span={8}>
                    <FieldLabel value={"И-мэйл хаяг"} />
                    <FieldText loading={loading} onChanged={fieldOnChanged} keyfield='email' defaultValue={data.email} />
                </Col>
            </Row>
            <div style={{ marginTop: 24, display: 'flex' }}>
                {loadingSave ? (
                    <LoadSpin />
                ) : (
                    edit && <ButtonBase onClick={onSave} text={"Хадгалах"} />
                )}
            </div>
        </div>
    );
}

export default UserDtl;