import React from 'react';
import { NumericFormat } from 'react-number-format';

const Number = (props) => {
    return (
        <div style={{ textAlign: props?.textAlign ? props?.textAlign : 'right' }}>
            <NumericFormat value={props.value} displayType={'text'} thousandSeparator={true}></NumericFormat> <span>{props.symbol && props.value ? " ₮" : null}{props.csymbol ? props.csymbol : ""}</span>
        </div>
    );
}

export default Number;