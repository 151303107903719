import React, { useState, useContext } from 'react';
import { ReactComponent as Logo } from '../../Svg/logo.svg';
import loginAdmiCover from '../../Images/loginAdminCover.png';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { firebaseApp } from '../../API/firebaseApp';
import { AdminAuthContext } from '../../Context/AdminAuthContext';
import LoadSpin from '../../Components/Base/LoadSpin/LoadSpin';
import FieldCheckBox from '../../Components/Base/FieldCheckBox/FieldCheckBox';
import FieldPassword from '../../Components/Base/FieldPassword/FieldPassword';
import FieldText from '../../Components/Base/FieldText/FieldText';
import ColEdit from '../../Components/Base/ColEdit/ColEdit';
import ButtonDefault from '../../Components/Base/ButtonDefault/ButtonDefault';
import Warning from '../../Components/Base/Warning/Warning';

const AdminLogin = (props) => {

    const navigate = useNavigate();

    const { adminAuth, setAdminAuth } = useContext(AdminAuthContext);
    const [data, setData] = useState({});
    const [warning, setWarning] = useState();
    const auth = getAuth(firebaseApp);
    const [loading, setLoading] = useState(false);

    const fieldOnChanged = async (key, text, field, fieldname) => {
        if (field) {
            let tData = { ...data };
            tData[field] = key;
            if (fieldname) {
                tData[fieldname] = text;
            }

            setData(tData);
        }
    }

    const onLogin = async () => {
        setWarning();
        if (data?.email && data?.password) {

            let success = false;

            setLoading(true);
            await signInWithEmailAndPassword(auth, data.email, data.password).then((userCredential) => {
                setAdminAuth(userCredential._tokenResponse);
                localStorage.setItem("admin", JSON.stringify(userCredential._tokenResponse));
                success = true;
            }).catch((error) => {
                setWarning("Нэвтрэх нэр эсвэл нууц үг буруу байна.");
            });
            setLoading(false);

            if (success) {
                navigate("/admin/dashboard");
            }
        } else {
            setWarning("Нэвтрэх нэр болон нууц үг оруулна уу!");
        }
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <div style={{ display: 'flex', height: 580, boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.15)', borderRadius: 8, minWidth: 830, overflow: 'hidden' }}>
                <div style={{ flex: 1 }}>
                    <img width={415} src={loginAdmiCover} />
                </div>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ minWidth: 297 }}>
                        <Logo />
                        <div style={{ fontWeight: 600, fontSize: 20, color: '#212121', marginTop: 48 }}>
                            Нэвтрэх
                        </div>
                        <div style={{ marginTop: 44 }}>
                            <FieldText height={40} title="Имэйл" onChanged={fieldOnChanged} keyfield='email' defaultValue={data.email} />
                        </div>
                        <div style={{ marginTop: 24 }}>
                            <FieldPassword height={40} title="Нууц үг" onChanged={fieldOnChanged} keyfield='password' defaultValue={data.password} />
                        </div>
                        <div style={{ marginTop: 24, display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <FieldCheckBox title="Намайг сануулах" onChanged={fieldOnChanged} keyfield='remind' defaultValue={data.remind} />
                            </div>
                        </div>
                        <div style={{ marginTop: 30 }}>
                            {loading ? (
                                <LoadSpin />
                            ) : (
                                <ButtonDefault onClick={onLogin} height={40} color="#F8A50C" full text="НЭВТРЭХ" />
                            )}
                        </div>
                        <div style={{ marginTop: 16 }}>
                            <Warning value={warning} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminLogin;