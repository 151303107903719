export const ConstCss = {
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    flexCenter: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    textNormal: {
        fontSize: 14,
        color: '#212121'
    }
};

export const Color = {
    white: '#ffffff',
    black: '#000000',
    primary: '#0358A7',
    grayBackground: '#F7F7F7',
    grayHeaderBack: '#F2F2F2',
    backText: '#212121',
    blue: '#0358A7',
    blueBackground: '#0358A7',
    yellow: '#FFC700',
    gray: '#828282',
    graySecond: '#6A6D70',
    grayText: '#605E5C',
    blackSecond: '#32363A',
    red: '#ff0000',
    grayNoData: '#BABABA',
    greyTableText: '#201F1E',
    warn: '#E9730C',
    tagPrimary: '#CAE4F3',
    tagSecondary: '#E9E9E9',
    grayButton: '#CCCCCC',
    blueLink: '#0854A0'
};

export const uploadFileFormat = [
    {
        filetype: ".txt",
        name: "text/plain"
    }, {
        filetype: ".pdf",
        name: "application/pdf"
    },
    {
        filetype: ".docx",
        name: "application/vnd.ms-word"
    }, {
        filetype: ".xls",
        name: "application/vnd.ms-excel"
    }, {
        filetype: ".xlsx",
        name: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    },
    {
        filetype: ".png",
        name: "image/png"
    }, {
        filetype: ".jpg",
        name: "image/jpeg"
    }, {
        filetype: ".jpeg",
        name: "image/jpeg"
    }, {
        filetype: ".gif",
        name: "image/gif"
    }, {
        filetype: ".csv",
        name: "text/csv"
    }
];

export const locationData = [
    { key: 6500, text: 'Архангай аймаг' },
    { key: 8300, text: 'Баян- Өлгий аймаг' },
    { key: 6400, text: 'Баянхонгор аймаг' },
    { key: 6300, text: 'Булган аймаг' },
    { key: 8200, text: 'Говь- Алтай аймаг' },
    { key: 4400, text: 'Дорноговь аймаг' },
    { key: 2100, text: 'Дорнод аймаг' },
    { key: 4800, text: 'Дундговь аймаг' },
    { key: 8100, text: 'Завхан аймаг' },
    { key: 6200, text: 'Өвөрхангай аймаг' },
    { key: 4600, text: 'Өмнөговь аймаг' },
    { key: 2200, text: 'Сүхбаатар аймаг ' },
    { key: 4300, text: 'Сэлэнгэ аймаг' },
    { key: 4100, text: 'Төв аймаг' },
    { key: 8500, text: 'Увс аймаг' },
    { key: 8400, text: 'Ховд аймаг' },
    { key: 6700, text: 'Хөвсгөл аймаг' },
    { key: 2300, text: 'Хэнтий аймаг' },
    { key: 4500, text: 'Дархан- Уул аймаг' },
    { key: 6100, text: 'Орхон аймаг' },
    { key: 4200, text: 'Говьсүмбэр аймаг' },
    { key: 1100, text: 'Улаанбаатар' },
    { key: 6504, text: 'Батцэнгэл сум' },
    { key: 6507, text: 'Булган сум' },
    { key: 6510, text: 'Жаргалант сум' },
    { key: 6513, text: 'Ихтамир сум' },
    { key: 6516, text: 'Өгийнуур сум' },
    { key: 6519, text: 'Өлзийт сум' },
    { key: 6522, text: 'Өндөр- Улаан сум' },
    { key: 6525, text: 'Тариат сум' },
    { key: 6528, text: 'Төвшрүүлэх сум' },
    { key: 6531, text: 'Хайрхан сум' },
    { key: 6534, text: 'Хангай сум' },
    { key: 6537, text: 'Хашаат сум' },
    { key: 6540, text: 'Хотонт сум' },
    { key: 6543, text: 'Цахир сум' },
    { key: 6546, text: 'Цэнхэр сум' },
    { key: 6549, text: 'Цэцэрлэг сум' },
    { key: 6552, text: 'Чулуут сум' },
    { key: 6501, text: 'Эрдэнэбулган сум' },
    { key: 6555, text: 'Эрдэнэмандал сум' },
    { key: 8328, text: 'Сагсай сум' },
    { key: 8331, text: 'Толбо сум' },
    { key: 8334, text: 'Улаанхус сум' },
    { key: 8337, text: 'Цэнгэл сум' },
    { key: 8304, text: 'Алтай сум' },
    { key: 8307, text: 'Алтанцөгц сум' },
    { key: 8310, text: 'Баяннуур сум' },
    { key: 8313, text: 'Бугат сум' },
    { key: 8316, text: 'Булган сум' },
    { key: 8319, text: 'Буянт сум' },
    { key: 8322, text: 'Дэлүүн сум' },
    { key: 8325, text: 'Ногооннуур сум' },
    { key: 8301, text: 'Өлгий сум' },
    { key: 6404, text: 'Баацагаан сум' },
    { key: 6416, text: 'Баян- Овоо сум' },
    { key: 6419, text: 'Баян- Өндөр сум' },
    { key: 6407, text: 'Баянбулаг сум' },
    { key: 6410, text: 'Баянговь сум' },
    { key: 6413, text: 'Баянлиг сум' },
    { key: 6401, text: 'Баянхонгор сум' },
    { key: 6422, text: 'Баянцагаан сум' },
    { key: 6425, text: 'Богд сум' },
    { key: 6428, text: 'Бөмбөгөр сум' },
    { key: 6431, text: 'Бууцагаан сум' },
    { key: 6434, text: 'Галуут сум' },
    { key: 6437, text: 'Гурванбулаг сум' },
    { key: 6440, text: 'Жаргалант сум' },
    { key: 6443, text: 'Жинст сум' },
    { key: 6446, text: 'Заг сум' },
    { key: 6449, text: 'Өлзийт сум' },
    { key: 6452, text: 'Хүрээмарал сум' },
    { key: 6455, text: 'Шинэжинст сум' },
    { key: 6458, text: 'Эрдэнэцогт сум' },
    { key: 6304, text: 'Баян- Агт сум' },
    { key: 6307, text: 'Баяннуур сум' },
    { key: 6310, text: 'Бугат сум' },
    { key: 6301, text: 'Булган сум' },
    { key: 6313, text: 'Бүрэгхангай сум' },
    { key: 6316, text: 'Гурванбулаг сум' },
    { key: 6319, text: 'Дашинчилэн сум' },
    { key: 6322, text: 'Могод сум' },
    { key: 6325, text: 'Орхон сум' },
    { key: 6328, text: 'Рашаант сум' },
    { key: 6331, text: 'Сайхан сум' },
    { key: 6334, text: 'Сэлэнгэ сум' },
    { key: 6337, text: 'Тэшиг сум' },
    { key: 6340, text: 'Хангал сум' },
    { key: 6343, text: 'Хишиг- Өндөр сум' },
    { key: 6346, text: 'Хутаг- Өндөр сум' },
    { key: 8204, text: 'Алтай сум' },
    { key: 8207, text: 'Баян- Уул сум' },
    { key: 8210, text: 'Бигэр сум' },
    { key: 8213, text: 'Бугат сум' },
    { key: 8216, text: 'Дарив сум' },
    { key: 8219, text: 'Дэлгэр сум' },
    { key: 8201, text: 'Есөнбулаг сум' },
    { key: 8222, text: 'Жаргалан сум' },
    { key: 8225, text: 'Тайшир сум' },
    { key: 8228, text: 'Тонхил сум' },
    { key: 8231, text: 'Төгрөг сум' },
    { key: 8234, text: 'Халиун сум' },
    { key: 8237, text: 'Хөхморьт сум' },
    { key: 8240, text: 'Цогт сум' },
    { key: 8243, text: 'Цээл сум' },
    { key: 8246, text: 'Чандмань сум' },
    { key: 8249, text: 'Шарга сум' },
    { key: 8252, text: 'Эрдэнэ сум' },
    { key: 4404, text: 'Айраг сум' },
    { key: 4407, text: 'Алтанширээ сум' },
    { key: 4410, text: 'Даланжаргалан сум' },
    { key: 4413, text: 'Дэлгэрэх сум' },
    { key: 4416, text: 'Замын- Үүд сум' },
    { key: 4419, text: 'Иххэт сум' },
    { key: 4422, text: 'Мандах сум' },
    { key: 4425, text: 'Өргөн сум' },
    { key: 4401, text: 'Сайншанд сум' },
    { key: 4428, text: 'Сайхандулаан сум' },
    { key: 4431, text: 'Улаанбадрах сум' },
    { key: 4434, text: 'Хатанбулаг сум' },
    { key: 4437, text: 'Хөвсгөл сум' },
    { key: 4440, text: 'Эрдэнэ сум' },
    { key: 2110, text: 'Баян- Уул сум' },
    { key: 2104, text: 'Баяндун сум' },
    { key: 2107, text: 'Баянтүмэн сум' },
    { key: 2113, text: 'Булган сум' },
    { key: 2116, text: 'Гурванзагал сум' },
    { key: 2119, text: 'Дашбалбар сум' },
    { key: 2122, text: 'Матад сум' },
    { key: 2125, text: 'Сэргэлэн сум' },
    { key: 2128, text: 'Халх гол сум' },
    { key: 2131, text: 'Хөлөнбуйр сум' },
    { key: 2101, text: 'Хэрлэн сум' },
    { key: 2134, text: 'Цагаан- Овоо сум' },
    { key: 2137, text: 'Чойбалсан сум' },
    { key: 2140, text: 'Чулуунхороот сум' },
    { key: 4804, text: 'Адаацаг сум' },
    { key: 4807, text: 'Баянжаргалан сум' },
    { key: 4810, text: 'Говь- Угтаал сум' },
    { key: 4813, text: 'Гурвансайхан сум' },
    { key: 4816, text: 'Дэлгэрхангай сум' },
    { key: 4819, text: 'Дэлгэрцогт сум' },
    { key: 4822, text: 'Дэрэн сум' },
    { key: 4825, text: 'Луус сум' },
    { key: 4828, text: 'Өлзийт сум' },
    { key: 4831, text: 'Өндөршил сум' },
    { key: 4801, text: 'Сайнцагаан сум' },
    { key: 4834, text: 'Сайхан- Овоо сум' },
    { key: 4837, text: 'Хулд сум' },
    { key: 4840, text: 'Цагаандэлгэр сум' },
    { key: 4843, text: 'Эрдэнэдалай сум' },
    { key: 8140, text: 'Тосонцэнгэл сум' },
    { key: 8143, text: 'Түдэвтэй сум' },
    { key: 8146, text: 'Тэлмэн сум' },
    { key: 8149, text: 'Тэс сум' },
    { key: 8101, text: 'Улиастай сум' },
    { key: 8152, text: 'Ургамал сум' },
    { key: 8155, text: 'Цагаанхайрхан сум' },
    { key: 8158, text: 'Цагаанчулуут сум' },
    { key: 8161, text: 'Цэцэн- Уул сум' },
    { key: 8164, text: 'Шилүүстэй сум' },
    { key: 8167, text: 'Эрдэнэхайрхан сум' },
    { key: 8170, text: 'Яруу сум' },
    { key: 8104, text: 'Алдархаан сум' },
    { key: 8107, text: 'Асгат сум' },
    { key: 8110, text: 'Баянтэс сум' },
    { key: 8113, text: 'Баянхайрхан сум' },
    { key: 8116, text: 'Дөрвөлжин сум' },
    { key: 8119, text: 'Завханмандал сум' },
    { key: 8122, text: 'Идэр сум' },
    { key: 8125, text: 'Их- Уул сум' },
    { key: 8128, text: 'Нөмрөг сум' },
    { key: 8131, text: 'Отгон сум' },
    { key: 8134, text: 'Сантмаргац сум' },
    { key: 8137, text: 'Сонгино сум' },
    { key: 6201, text: 'Арвайхээр сум' },
    { key: 6204, text: 'Баруунбаян- Улаан сум' },
    { key: 6207, text: 'Бат- Өлзий сум' },
    { key: 6213, text: 'Баян- Өндөр сум' },
    { key: 6210, text: 'Баянгол сум' },
    { key: 6216, text: 'Богд сум' },
    { key: 6219, text: 'Бүрд сум' },
    { key: 6222, text: 'Гучин- Ус сум' },
    { key: 6225, text: 'Есөнзүйл сум' },
    { key: 6228, text: 'Зүүнбаян- Улаан сум' },
    { key: 6231, text: 'Нарийнтээл сум' },
    { key: 6234, text: 'Өлзийт сум' },
    { key: 6237, text: 'Сант сум' },
    { key: 6240, text: 'Тарагт сум' },
    { key: 6243, text: 'Төгрөг сум' },
    { key: 6246, text: 'Уянга сум' },
    { key: 6249, text: 'Хайрхандулаан сум' },
    { key: 6252, text: 'Хархорин сум' },
    { key: 6255, text: 'Хужирт сум' },
    { key: 4607, text: 'Баян- Овоо сум' },
    { key: 4604, text: 'Баяндалай сум' },
    { key: 4610, text: 'Булган сум' },
    { key: 4613, text: 'Гурвантэс сум' },
    { key: 4601, text: 'Даланзадгад сум' },
    { key: 4616, text: 'Мандал- Овоо сум' },
    { key: 4619, text: 'Манлай сум' },
    { key: 4622, text: 'Ноён сум' },
    { key: 4625, text: 'Номгон сум' },
    { key: 4628, text: 'Сэврэй сум' },
    { key: 4631, text: 'Ханбогд сум' },
    { key: 4634, text: 'Ханхонгор сум' },
    { key: 4637, text: 'Хүрмэн сум' },
    { key: 4640, text: 'Цогт- Овоо сум' },
    { key: 4643, text: 'Цогтцэций сум' },
    { key: 2204, text: 'Асгат сум' },
    { key: 2201, text: 'Баруун-Урт сум' },
    { key: 2207, text: 'Баяндэлгэр сум' },
    { key: 2210, text: 'Дарьганга сум' },
    { key: 2213, text: 'Мөнххаан сум' },
    { key: 2216, text: 'Наран сум' },
    { key: 2219, text: 'Онгон сум' },
    { key: 2222, text: 'Сүхбааатар сум' },
    { key: 2225, text: 'Түвшинширээ сум' },
    { key: 2228, text: 'Түмэнцогт сум' },
    { key: 2231, text: 'Уулбаян сум' },
    { key: 2234, text: 'Халзан сум' },
    { key: 2237, text: 'Эрдэнэцагаан сум ' },
    { key: 4322, text: 'Мандал сум' },
    { key: 4325, text: 'Орхон сум' },
    { key: 4328, text: 'Орхонтуул сум' },
    { key: 4331, text: 'Сайхан сум' },
    { key: 4334, text: 'Сант сум' },
    { key: 4301, text: 'Сүхбаатар сум' },
    { key: 4337, text: 'Түшиг сум' },
    { key: 4343, text: 'Хушаат сум' },
    { key: 4340, text: 'Хүдэр сум' },
    { key: 4346, text: 'Цагааннуур сум' },
    { key: 4349, text: 'Шаамар сум' },
    { key: 4304, text: 'Алтанбулаг сум' },
    { key: 4307, text: 'Баруунбүрэн сум' },
    { key: 4310, text: 'Баянгол сум' },
    { key: 4313, text: 'Ерөө сум' },
    { key: 4316, text: 'Жавхлант сум' },
    { key: 4319, text: 'Зүүнбүрэн сум' },
    { key: 4104, text: 'Алтанбулаг сум' },
    { key: 4107, text: 'Аргалант сум' },
    { key: 4110, text: 'Архуст сум' },
    { key: 4113, text: 'Батсүмбэр сум' },
    { key: 4125, text: 'Баян- Өнжүүл сум' },
    { key: 4116, text: 'Баян сум' },
    { key: 4119, text: 'Баяндэлгэр сум' },
    { key: 4122, text: 'Баянжаргалан сум' },
    { key: 4128, text: 'Баянхангай сум' },
    { key: 4131, text: 'Баянцагаан сум' },
    { key: 4134, text: 'Баянцогт сум' },
    { key: 4137, text: 'Баянчандмань сум' },
    { key: 4140, text: 'Борнуур сум' },
    { key: 4143, text: 'Бүрэн сум' },
    { key: 4146, text: 'Дэлгэрхаан сум' },
    { key: 4149, text: 'Жаргалант сум' },
    { key: 4152, text: 'Заамар сум' },
    { key: 4101, text: 'Зуунмод сум' },
    { key: 4155, text: 'Лүн сум' },
    { key: 4158, text: 'Мөнгөнморьт сум' },
    { key: 4161, text: 'Өндөрширээт сум' },
    { key: 4164, text: 'Сүмбэр сум' },
    { key: 4167, text: 'Сэргэлэн сум' },
    { key: 4170, text: 'Угтаалцайдам сум' },
    { key: 4173, text: 'Цээл сум' },
    { key: 4176, text: 'Эрдэнэ сум' },
    { key: 4179, text: 'Эрдэнэсант сум' },
    { key: 8504, text: 'Баруунтуруун сум' },
    { key: 8507, text: 'Бөхмөрөн сум' },
    { key: 8510, text: 'Давст сум' },
    { key: 8513, text: 'Завхан сум' },
    { key: 8516, text: 'Зүүнговь сум' },
    { key: 8519, text: 'Зүүнхангай сум ' },
    { key: 8522, text: 'Малчин сум' },
    { key: 8525, text: 'Наранбулаг сум' },
    { key: 8528, text: 'Өлгий сум' },
    { key: 8531, text: 'Өмнөговь сум' },
    { key: 8534, text: 'Өндөрхангай сум' },
    { key: 8537, text: 'Сагил сум' },
    { key: 8540, text: 'Тариалан сум' },
    { key: 8543, text: 'Түргэн сум' },
    { key: 8546, text: 'Тэс сум' },
    { key: 8501, text: 'Улаангом сум' },
    { key: 8549, text: 'Ховд сум' },
    { key: 8552, text: 'Хяргас сум' },
    { key: 8555, text: 'Цагаанхайрхан сум' },
    { key: 8404, text: 'Алтай сум' },
    { key: 8407, text: 'Булган сум' },
    { key: 8410, text: 'Буянт сум' },
    { key: 8413, text: 'Дарви  сум' },
    { key: 8416, text: 'Дөргөн сум' },
    { key: 8419, text: 'Дуут сум ' },
    { key: 8401, text: 'Жаргалант сум' },
    { key: 8422, text: 'Зэрэг сум' },
    { key: 8425, text: 'Манхан сум' },
    { key: 8428, text: 'Мөнххайрхан сум' },
    { key: 8431, text: 'Мөст сум ' },
    { key: 8434, text: 'Мянгад сум ' },
    { key: 8437, text: 'Үенч сум' },
    { key: 8440, text: 'Ховд сум' },
    { key: 8443, text: 'Цэцэг сум ' },
    { key: 8446, text: 'Чандмань сум' },
    { key: 8449, text: 'Эрдэнэбүрэн сум' },
    { key: 6704, text: 'Алаг- Эрдэнэ сум' },
    { key: 6707, text: 'Арбулаг сум' },
    { key: 6710, text: 'Баянзүрх сум' },
    { key: 6713, text: 'Бүрэнтогтох сум' },
    { key: 6716, text: 'Галт сум' },
    { key: 6719, text: 'Жаргалант сум' },
    { key: 6722, text: 'Их- Уул сум' },
    { key: 6701, text: 'Мөрөн сум' },
    { key: 6725, text: 'Рашаант сум' },
    { key: 6728, text: 'Рэнчинлхүмбэ сум' },
    { key: 6731, text: 'Тариалан сум' },
    { key: 6734, text: 'Тосонцэнгэл сум' },
    { key: 6737, text: 'Төмөрбулаг сум' },
    { key: 6740, text: 'Түнэл сум' },
    { key: 6743, text: 'Улаан- Уул сум' },
    { key: 6746, text: 'Ханх сум' },
    { key: 6752, text: 'Цагаан- Уул сум' },
    { key: 6755, text: 'Цагаан- Үүр сум' },
    { key: 6749, text: 'Цагааннуур сум' },
    { key: 6758, text: 'Цэцэрлэг сум' },
    { key: 6761, text: 'Чандмань- Өндөр сум' },
    { key: 6764, text: 'Шинэ- Идэр сум' },
    { key: 6767, text: 'Эрдэнэбулган сум' },
    { key: 2304, text: 'Батноров сум' },
    { key: 2307, text: 'Батширээт сум' },
    { key: 2310, text: 'Баян- Адарга сум' },
    { key: 2313, text: 'Баянмөнх сум' },
    { key: 2316, text: 'Баян-Овоо сум' },
    { key: 2319, text: 'Баянхутаг сум' },
    { key: 2322, text: 'Биндэр сум' },
    { key: 2350, text: 'Бор- Өндөр сум' },
    { key: 2325, text: 'Галшир сум' },
    { key: 2328, text: 'Дадал сум' },
    { key: 2331, text: 'Дархан сум' },
    { key: 2334, text: 'Дэлгэрхаан сум' },
    { key: 2337, text: 'Жаргалтхаан сум' },
    { key: 2340, text: 'Мөрөн сум' },
    { key: 2343, text: 'Норовлин сум' },
    { key: 2346, text: 'Өмнөдэлгэр сум' },
    { key: 2301, text: 'Хэрлэн сум' },
    { key: 2349, text: 'Цэнхэрмандал сум' },
    { key: 4510, text: 'Шарын гол сум' },
    { key: 4507, text: 'Хонгор сум' },
    { key: 4504, text: 'Орхон сум' },
    { key: 4501, text: 'Дархан сум' },
    { key: 6104, text: 'Жаргалант сум' },
    { key: 6101, text: 'Баян- Өндөр сум' },
    { key: 4201, text: 'Сүмбэр сум' },
    { key: 4207, text: 'Шивээговь сум' },
    { key: 4204, text: 'Баянтал сум' },
    { key: 1125, text: 'Чингэлтэй дүүрэг' },
    { key: 1116, text: 'Сонгинохайрхан дүүрэг' },
    { key: 1107, text: 'Баянгол дүүрэг' },
    { key: 1122, text: 'Хан-Уул дүүрэг' },
    { key: 1110, text: 'Баянзүрх дүүрэг' },
    { key: 1119, text: 'Сүхбаатар дүүрэг' },
    { key: 1101, text: 'Багануур дүүрэг' },
    { key: 1113, text: 'Налайх дүүрэг' },
    { key: 1104, text: 'Багахангай дүүрэг' }
];

export const corporateStrategyData = [
    {
        key: 0,
        text: 'Бага өртгөөр манлайлагч',
        corporate: 'Энэ бол бизнесийн ертөнцийн хувьд наймааны дэлгүүртэй адил юм. Компани нь өрсөлдөгчдөөсөө хямд үнээр бүтээгдэхүүн, үйлчилгээг санал болгохыг хичээдэг. Уолмартыг бодоод үз - тэд маш олон янзын зүйлийг зардаг бөгөөд ихэвчлэн нэлээд хямд үнээр зардаг.',
        hrsuite: '<ul><li>Ажилд авах үйл явцын зардлын үр дүнд анхаарах.</li><li>Ажилчдын үр ашгийг дээшлүүлэх сургалтын хөтөлбөрүүдийг хэрэгжүүлэх.</li><li>Зардлыг бууруулахын тулд зарим ажлыг аутсорсинг хийх эсвэл автоматжуулах.</li></ul>'
    },
    {
        key: 1,
        text: 'Ялгарал',
        corporate: 'Энд компани бүтээгдэхүүн, үйлчилгээгээр бусдаас ялгарахыг хичээдэг. Энэ нь өөр хэнд ч байхгүй чамин амтыг зардаг зайрмагны дэлгүүр байхтай төстэй. Бүтээгдэхүүн нь маш өвөрмөц бөгөөд хүмүүс энэ өвөрмөц байдлын төлөө илүү их мөнгө төлөхөд бэлэн байдаг.',
        hrsuite: '<ul><li>Өвөрмөц ур чадвар, туршлагатай ажилчдыг ажилд авна. Ажлын байрны бүтээлч, шинэлэг соёлыг төлөвшүүлэх.</li><li>Бүтээгдэхүүн, үйлчилгээгээ ялгахад хувь нэмрээ оруулсан ажилчдад урамшууллын тогтолцоог нэвтрүүлэх.</li></ul>'
    },
    {
        key: 2,
        text: 'Төвлөрөх стратеги',
        corporate: 'Энэ тохиолдолд компани нь тодорхой бүлэг үйлчлүүлэгчид эсвэл тодорхой бүтээгдэхүүнд төвлөрдөг. Энэ нь зөвхөн комик ном зардаг дэлгүүр нээхтэй адил юм - та комик ном сонирхогчдын зах зээлд анхаарлаа хандуулж байна.',
        hrsuite: '<ul><li>Зах зээлийг ойлгодог мэргэжилтнүүдийг ажилд авна.</li><li>Ажилчдыг төрөлжсөн сургалтад хамруулж, хөгжүүлнэ.</li><li>Шилдэг авьяастнуудыг татахын тулд салбартаа хүчирхэг ажил олгогч брэндийг бий болгох.</li></ul>'
    },
    {
        key: 3,
        text: 'Инноваци',
        corporate: 'Энэхүү стратеги нь шинэ, гайхалтай зүйлсийг бий болгохыг зорьдог. Apple-ийг iPhone-г анх танилцуулж байх үеийг бодоод үз дээ. Энэ стратегийг ашигладаг компаниуд үргэлж дараагийн том зүйлийг бүтээхийг хичээдэг.',
        hrsuite: '<ul><li>Ажилчдын дунд инноваци, тасралтгүй суралцах соёлыг дэмжинэ.</li><li>Санаа хуваалцах платформуудыг хэрэгжүүлж, шинэлэг санааг урамшуулна.</li><li>Шинэ хэтийн төлөвийг бий болгохын тулд янз бүрийн мэргэжилтэй хүмүүсийг ажилд авна. </li></ul>'
    },
    {
        key: 4,
        text: 'Өсөлтийн стратеги',
        corporate: 'Энэ нь үр тарьж, том цэцэрлэг ургуулах гэж оролдсонтой адил юм. Компани томорч, илүү их мөнгө олохын тулд шинэ дэлгүүрүүд нээх, шинэ зах зээлд гарах эсвэл шинэ бүтээгдэхүүн гаргах боломжтой.',
        hrsuite: '<ul><li>Өсөлтийг дэмжихийн тулд ажиллах хүчний хэрэгцээг урьдчилан төлөвлөх.</li><li>Шинэ бизнесийн нэгжүүдэд манлайлагчдыг бий болгох манлайллын хөтөлбөрүүдийг боловсруулах.</li><li>Шинэ ажилчдыг үр дүнтэй нэгтгэхийн тулд хүчтэй ажилд авах үйл явцыг бий болгох. </li></ul>'
    },
    {
        key: 5,
        text: 'Хэрэглэгч төвтэй',
        corporate: 'Энд компани нь хэрэглэгчээ маш их аз жаргалтай байлгахыг хичээдэг. Таныг гайхалтай байлгахын тулд чадах бүхнээ хийдэг зочид буудлыг бодоорой. Аз жаргалтай үйлчлүүлэгчид буцаж ирдэг бөгөөд заримдаа илүү их мөнгө төлөхөд бэлэн байдаг.',
        hrsuite: '<ul><li>Ажилчдыг харилцагчийн үйлчилгээ болон харилцагчийн харилцааны менежментэд сургах.</li><li>Хэрэглэгчийн сэтгэл ханамжийг эрхэмлэдэг соёлыг төлөвшүүлэх.</li><li>Үйлчлүүлэгчдийн талаарх мэдээллийг тайлагнахын тулд ажилтнуудад зориулсан санал хүсэлтийн системийг нэвтрүүлэх. </li></ul>'
    },
    {
        key: 6,
        text: 'Түншлэл эсвэл холбоо',
        corporate: 'Заримдаа тоглоомд ганцаараа ялах нь хэцүү байдаг тул компани өөр нэгтэй нэгддэг. Кинон дээр супер баатрууд нэгдэж хүчирхэг муу санаатныг ялдаг тухай бод. Компаниуд нэг зорилгод хүрэхийн тулд хамтран ажиллах боломжтой.',
        hrsuite: '<ul><li>Ажилчдын дунд хэлэлцээр хийх, харилцааны менежментийн ур чадварыг хөгжүүлэх.</li><li>Хамтрагч компаниудын ажилчдад баг бүрдүүлэх үйл ажиллагааг хэрэгжүүлнэ.</li><li>Түнш компаниудын хооронд хүний нөөцийн бодлого, практикийг уялдуулах. </li></ul>'
    },
    {
        key: 7,
        text: 'Үйл ажиллагааны үр ашиг',
        corporate: 'Энэ нь компани хийж буй зүйлдээ үнэхээр сайн байх явдал юм. Тослогоо сайтай машинтай адил энэ нь илүү үр ашигтай ажиллах, мөнгөө хэмнэх арга замыг хайж олдог.',
        hrsuite: '<ul><li>Ажилчдыг үр дүнтэй байдлаар ажилд авах, мөн сургах, удирдахад хүний нөөцийн үйл явцыг оновчтой болгох.</li><li>Өгөгдөлд тулгуурласан шийдвэр гаргахын тулд хүний нөөцийн аналитик ашиглах.</li><li>Ажилчдыг хэмнэлттэй дадал, үйл явцыг сайжруулахад сургах. </li></ul>'
    },
    {
        key: 8,
        text: 'Глобал тэлэлт',
        corporate: 'Заримдаа компани өөр өөр улс оронд тоглохыг хүсдэг. Дэлхийд аялан тоглолт хийх гэж байгаа алдартай дуучин шиг. Ингэснээр тэд дэлхийн өнцөг булан бүрт байгаа хүмүүст бүтээгдэхүүнээ борлуулж чадна.',
        hrsuite: '<ul><li>Янз бүрийн бүс нутагт авъяас чадварыг олж авах стратегийг боловсруулах.</li><li>Ажилчдыг соёлын сургалт, хэлний хөтөлбөрөөр хангана.</li><li>Орон нутгийн хууль, соёлд нийцсэн дэлхийн хүний нөөцийн бодлогыг бий болгох. </li></ul>'
    },
    {
        key: 9,
        text: 'Дасан зохицох чадвар ба авхаалж самбаа',
        corporate: 'Дасан зохицох чадвар ба авхаалж самбаа: Энэ стратеги нь хурдан бөгөөд уян хатан нинжатай адил юм. Компани өөрчлөлтөд дасан зохицож, шинэ боломжуудыг ашиглахад үргэлж бэлэн байдаг. ',
        hrsuite: '<ul><li>Ажилчдын дунд уян хатан, дасан зохицох соёлыг төлөвшүүлэх.</li><li>Өөрчлөлтийн менежментийн ур чадварыг хөгжүүлэх.</li><li>Шуурхай санал хүсэлт, тохируулга хийх боломжийг олгодог уян хатан гүйцэтгэлийн удирдлагын системийг нэвтрүүлэх. </li></ul>'
    }
];

export const constData = {
    rol_status: [
        {
            "key": "Y",
            "text": "Идэвхтэй"
        },
        {
            "key": "N",
            "text": "Идэвхгүй"
        },
    ],
    sidepartment_status: [
        {
            "key": "Active",
            "text": "Идэвхтэй"
        },
        {
            "key": "Inactive",
            "text": "Идэвхгүй"
        },
        {
            "key": "Liquidate",
            "text": "Татан буугдсан"
        }
    ],
    hrManagerPos_Type: [
        {
            "key": "D",
            "text": "Шууд удирдлага"
        },
        {
            "key": "N",
            "text": "Чиг үүргийн удирдлага"
        },
        {
            "key": "R",
            "text": "Тайлагнах удирдлага"
        },
        {
            "key": "L",
            "text": "Шугаман удирдлага"
        }
    ],
    Gender: [
        {
            "key": "M",
            "text": "Эр"
        },
        {
            "key": "F",
            "text": "Эм"
        }
    ],
    hrEmpdPunishment_Type: [
        {
            "key": 1,
            "text": "Сануулах"
        },
        {
            "key": 2,
            "text": "Цалинг хувиар бууруулах"
        },
        {
            "key": 3,
            "text": "Төрийн албанд 3 жил эргэж орохгүйгээр халах"
        },
        {
            "key": 4,
            "text": "Төрийн албанаас халах"
        }
    ],
    MaritalStatus: [
        {
            "key": "M",
            "text": "Гэрлэсэн"
        },
        {
            "key": "U",
            "text": "Гэрлээгүй"
        },
        {
            "key": "W",
            "text": "Бэлэвсэн"
        },
        {
            "key": "K",
            "text": "Тодорхойгүй"
        }
    ],
    hrEmpTraining_Type: [
        {
            "key": "S",
            "text": "Хувиараа"
        },
        {
            "key": "C",
            "text": "Байгууллагаас"
        },
        {
            "key": "A",
            "text": "Өөр байгууллагаас"
        }
    ],
    hrBenefitPackageDtl_FreqType: [
        {
            "key": "D",
            "text": "Өдөр"
        },
        {
            "key": "M",
            "text": "Сар"
        }
    ],
    Gov_shift_Type: [
        {
            "key": "SH",
            "text": "Албан байгууллага дотор шилжсэн"
        },
        {
            "key": "SJ",
            "text": "Албан тушаал дэвшүүлсэн"
        },
        {
            "key": "SD",
            "text": "Албан тушаал буурсан"
        },
        {
            "key": "SP",
            "text": "Түдгэлзүүлсэн"
        },
        {
            "key": "SL",
            "text": "Цалинд өөрчлөлт орсон"
        },
        {
            "key": "IT",
            "text": "Даатгуулагчийн төрөл өөрчлөгдсөн"
        },
        {
            "key": "LC",
            "text": "Хөдөлмөрийн нөхцөл өөрчлөгдсөн"
        },
        {
            "key": "SC",
            "text": "Бүтцийн өөрчлөлт"
        }
    ],
    Gov_Terminate_Type: [
        {
            "key": "MV",
            "text": "Байгууллага хооронд шилжүүлэн томилсон"
        },
        {
            "key": "MR",
            "text": "Байгууллага хооронд сэлгэн шилжүүлсэн"
        },
        {
            "key": "IN",
            "text": "Ажлаас халсан"
        },
        {
            "key": "IE",
            "text": "Ажилтны санаачлагаар чөлөөлсөн"
        },
        {
            "key": "IL",
            "text": "Түр чөлөөлсөн"
        },
        {
            "key": "IC",
            "text": "Тэтгэвэрт гарсан"
        },
        {
            "key": "ID",
            "text": "Нас барсан"
        },
        {
            "key": "CE",
            "text": "Гэрээний хугацаа дууссан"
        },
        {
            "key": "LV",
            "text": "Чөлөөлсөн"
        }
    ],
    hrContract_LabourCondition: [
        {
            "key": "N",
            "text": "Хэвийн"
        },
        {
            "key": "A",
            "text": "Хэвийн бус"
        },
        {
            "key": "H",
            "text": "Хүнд"
        },
        {
            "key": "P",
            "text": "Хортой"
        },
        {
            "key": "W",
            "text": "Халуун"
        }
    ],
    Gov_Employment_Type: [
        {
            "key": "OT",
            "text": "Өөр байгууллагад ажилласан түүх"
        },
        {
            "key": "HR",
            "text": "Ажилд авсан түүх"
        },
        {
            "key": "SH",
            "text": "Дотоод хөдөлгөөний түүх"
        },
        {
            "key": "TR",
            "text": "Ажлаас чөлөөлсөн түүх"
        }
    ],
    Gov_Employment_Type_Double_Emp: [
        {
            "key": "HR",
            "text": "Ажилд авсан түүх"
        },
        {
            "key": "SH",
            "text": "Дотоод хөдөлгөөний түүх"
        },
        {
            "key": "TR",
            "text": "Ажлаас чөлөөлсөн түүх"
        }
    ],
    hrEmployee_Status: [
        {
            "key": "ACTIVE",
            "text": "Ажиллаж байгаа",
            "description": "Давхар ажил эрхлэлт, Идэвхтэй, Түр чөлөөлсөн"
        },
        {
            "key": "INACTIVE",
            "text": "Ажиллахгүй байгаа",
            "description": "Бүртгэсэн, Ажлаас гарсан",
            "divider": true
        },
        {
            "key": "RG",
            "text": "Бүртгэсэн"
        },
        {
            "key": "AC",
            "text": "Идэвхтэй"
        },
        {
            "key": "AC1",
            "text": "Давхар ажил эрхлэлт"
        },
        {
            "key": "IL",
            "text": "Түр чөлөөлсөн"
        },
        {
            "key": "IN",
            "text": "Ажлаас гарсан"
        }
    ],
    hrEmployee_Status_Resource_Filter: [
        {
            "key": "ACTIVE",
            "text": "Нөөц",
            "description": "Ерөнхий шалгалт өгч тэнцсэн нөөц, Удирдах ажилтн..."
        },
        {
            "key": "INACTIVE",
            "text": "Нөөцөөс хасагдсан",
            "description": "Нөөцөөс хасагдсан"
        },
        {
            "key": "BLACKLIST",
            "text": "Хар жагсаалт",
            "description": "Хар жагсаалт",
            "divider": true
        },
        {
            "key": "F1",
            "text": "Ерөнхий шалгалт өгч тэнцсэн нөөц"
        },
        {
            "key": "F2",
            "text": "Удирдах ажилтны нөөц"
        },
        {
            "key": "F3",
            "text": "Төрийн жинхэнэ албаны нөөц"
        },
        {
            "key": "F4",
            "text": "Төрийн үйлчилгээний удирдах ажилтны нөөц"
        },
        {
            "key": "F5",
            "text": "Ажиллаж байгаа нөөц"
        },
        {
            "key": "F6",
            "text": "Нөөцөөс хасагдсан"
        },
        {
            "key": "F7",
            "text": "Хар жагсаалтанд бүртгэгдсэн"
        }
    ],
    hrEmployee_Status_Resource: [
        {
            "key": "F1",
            "text": "Ерөнхий шалгалт өгч тэнцсэн нөөц"
        },
        {
            "key": "F2",
            "text": "Удирдах ажилтны нөөц"
        },
        {
            "key": "F3",
            "text": "Төрийн жинхэнэ албаны нөөц"
        },
        {
            "key": "F4",
            "text": "Төрийн үйлчилгээний удирдах ажилтны нөөц"
        },
        {
            "key": "F5",
            "text": "Ажиллаж байгаа нөөц"
        },
        {
            "key": "F6",
            "text": "Нөөцөөс хасагдсан"
        },
        {
            "key": "F7",
            "text": "Хар жагсаалтанд бүртгэгдсэн"
        }
    ],
    siDepartment_DepType: [
        {
            "key": "B",
            "text": "Салбар"
        }
    ],
    hrPosition_Type: [
        {
            "key": "B",
            "text": "Үндсэн"
        },
        {
            "key": "C",
            "text": "Гэрээт"
        }
    ],
    Role_Module_Access: [
        {
            "key": "Edit",
            "text": "Засах"
        },
        {
            "key": "View",
            "text": "Харах"
        },
        {
            "key": "NoAccess",
            "text": "Эрхгүй"
        }
    ],
    Role_Type: [
        {
            "key": "System",
            "text": "Системийн"
        },
        {
            "key": "Custom",
            "text": "Үүсгэсэн"
        }
    ],
    User_Status_Filter: [
        {
            "key": "All",
            "text": "Бүгд"
        }
    ],
    User_Status: [
        {
            "key": "A",
            "text": "Идэвхтэй"
        },
        {
            "key": "L",
            "text": "Түгжсэн"
        },
        {
            "key": "I",
            "text": "Идэвхгүй"
        },
        {
            "key": "D",
            "text": "Устгасан"
        }
    ],
    hrempinvalid_reason: [
        {
            "key": "trauma",
            "text": "Гэмтлээр"
        },
        {
            "key": "disease",
            "text": "Өвчнөөр"
        },
        {
            "key": "congenital",
            "text": "Төрөлхийн"
        }
    ],
    hrempinvalid_duration: [
        {
            "key": "IA",
            "text": "3 сар"
        },
        {
            "key": "IB",
            "text": "6 сар"
        },
        {
            "key": "IC",
            "text": "12 сар"
        },
        {
            "key": "ID",
            "text": "18 сар"
        },
        {
            "key": "IE",
            "text": "24 сар"
        },
        {
            "key": "IF",
            "text": "Хугацаагүй"
        }
    ],
    hrempinvalid_decision: [
        {
            "key": "Agreed",
            "text": "Тогтоосон"
        },
        {
            "key": "Canceled",
            "text": "Цуцалсан"
        }
    ],
    hrExtra_CalcType: [
        {
            "key": "PT",
            "text": "Хувиар"
        },
        {
            "key": "AT",
            "text": "Дүнгээр"
        }
    ],
    hrExtra_CalcType_Symbol: [
        {
            "key": "PT",
            "text": "%"
        },
        {
            "key": "AT",
            "text": "₮"
        }
    ],
    hrExtra_CalcMethod: [
        {
            "key": "SA",
            "text": "Тодорхой дүнгээр"
        },
        {
            "key": "SR",
            "text": "Дээд доод хязгаараар"
        }
    ],
    hrExtra_Frequency: [
        {
            "key": "DY",
            "text": "Өдрөөр"
        },
        {
            "key": "MT",
            "text": "Сараар"
        },
        {
            "key": "SE",
            "text": "Улирлаар"
        }
    ],
    hrSalarySchema_Grade: [
        {
            "key": "ТТ",
            "text": "Төрийн тусгай"
        },
        {
            "key": "ТӨ",
            "text": "Төрийн өндөр"
        },
        {
            "key": "АА",
            "text": "Төрийн захиргаа /Ажлын алба/"
        },
        {
            "key": "ТЗ",
            "text": "Төрийн захиргаа"
        },
        {
            "key": "ТҮ",
            "text": "Төрийн үйлчилгээ /эрүүл мэндээс бусад/"
        },
        {
            "key": "БТҮ",
            "text": "Төрийн үйлчилгээ /эрүүл мэндийн бусад/"
        },
        {
            "key": "ТҮБД",
            "text": "Төрийн үйлчилгээ /боловсрол/"
        },
        {
            "key": "ТҮМБ",
            "text": "Төрийн үйлчилгээ /мэргэжлийн байгууллага/"
        },
        {
            "key": "ТҮСУ",
            "text": "Төрийн үйлчилгээ /соёл урлаг/"
        },
        {
            "key": "ТҮШУ",
            "text": "Төрийн үйлчилгээ /шинжлэх ухаан/"
        },
        {
            "key": "ТҮЭМ",
            "text": "Төрийн үйлчилгээ /эрүүл мэнд/"
        },
        {
            "key": "ҮА",
            "text": "Төрийн тусгай /аудит/"
        },
        {
            "key": "ШҮ",
            "text": "Төрийн тусгай /шүүгч/"
        },
        {
            "key": "ПРО",
            "text": "Төрийн тусгай /прокурор/"
        },
        {
            "key": "ИТХТ",
            "text": "Төрийн улс төрийн /ИТХ-ын төлөөлөгч/"
        }
    ],
    year: [
        {
            "key": "2021",
            "text": "2021"
        },
        {
            "key": "2020",
            "text": "2020"
        },
        {
            "key": "2019",
            "text": "2019"
        },
        {
            "key": "2018",
            "text": "2018"
        }
    ],
    hrBasis_Type: [
        {
            "key": "EE",
            "text": "Ажил эрхлэлт"
        },
        {
            "key": "ED",
            "text": "Сахилгын шийтгэл"
        }
    ],
    licenseCategory: [
        {
            "key": "A",
            "text": "A"
        },
        {
            "key": "B",
            "text": "B"
        },
        {
            "key": "C",
            "text": "C"
        },
        {
            "key": "D",
            "text": "D"
        },
        {
            "key": "E",
            "text": "E"
        },
        {
            "key": "M",
            "text": "M"
        }
    ]
}