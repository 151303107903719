import React, { useState, useEffect } from 'react';
import { get, ref, getDatabase } from 'firebase/database';
import { firebaseApp } from '../../../API/firebaseApp';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../../Components/Base/PageHeader/PageHeader';
import GridDefaultShimmer from '../../../Components/Base/GridDefaultShimmer/GridDefaultShimmer';
import ColEdit from '../../../Components/Base/ColEdit/ColEdit';

const Product = (props) => {

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [oData, setOData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [columns, setColumns] = useState([
        {
            name: 'Дугаар',
            fieldName: ' id',
            minWidth: 160,
            maxWidth: 160,
            onRender: (item) => {
                return (
                    <ColEdit onClick={() => onEdit(item.id)}>{item.id}</ColEdit>
                );
            }
        },
        {
            name: 'Бүтээгдэхүүний нэр',
            fieldName: 'name',
            minColSize: 'mid'
        }, {
            name: 'Категори',
            fieldName: 'categoryname',
            minColSize: 'mid'
        }, {
            name: 'Үнэ',
            fieldName: 'price',
            minColSize: 'mid'
        }, {
            name: 'Төлөв',
            fieldName: 'active',
            onRender: (item) => {
                if (item.active) {
                    return (
                        <div>Идэвхтэй</div>
                    );
                } else {
                    return (
                        <div>Идэвхгүй</div>
                    );
                }
            }
        }
    ]);

    const getData = async () => {
        let tData = [];

        await get(ref(getDatabase(firebaseApp), "product")).then(response => {
            let resp = response.toJSON();
            if (resp) {
                Object.keys(resp)?.map(key => {
                    tData.push({ id: key, ...resp[key] });
                });
            }
        });

        tData.sort((a, b) => {
            if (a.created > b.created) {
                return -1;
            }
            if (a.created < b.created) {
                return 1;
            }
            return 0;
        });

        setData(tData);
        setOData(tData);
    }

    useEffect(() => {
        getData();
    }, []);

    const onSearch = (p_data) => {
        setData(p_data);
    }

    const onEdit = (pId) => {
        navigate("/admin/product/" + pId);
    }

    const onNew = () => {
        navigate("/admin/product/-1");
    }

    const onAfterSave = async () => {
        await getData();
    }

    return (
        <div>
            <PageHeader
                title="Бүтээгдэхүүн"
                buttonTitle="Бүтээгдэхүүн нэмэх"
                onButtonClick={onNew}
                dataCount={data?.length}
                columns={columns}
                data={oData}
                filteredData={data}
                onSearch={onSearch}
            />
            <GridDefaultShimmer
                data={data}
                columns={columns}
                loading={loading}
            />
        </div>
    );
}

export default Product;