import { get, getDatabase, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react';
import { firebaseApp } from '../../API/firebaseApp';
import Product from '../../Components/Product/Product';
import { Col, Row, List } from 'antd';
import LoadSpin from '../../Components/Base/LoadSpin/LoadSpin';

const Products = (props) => {

    const [product, setProduct] = useState([]);
    const [loading, setLoading] = useState(false);

    const getData = async () => {

        let tProduct = [];

        try {
            setLoading(true);
            await get(ref(getDatabase(firebaseApp), "product")).then(response => {
                let resp = response.toJSON();
                if (resp) {
                    Object.keys(resp)?.map(key => {
                        if (resp[key]?.active) {
                            tProduct.push({ id: key, ...resp[key] });
                        }
                    });
                }
            });
        } finally {
            setLoading(false);
        }

        tProduct.sort((a, b) => {
            if (a.created < b.created) {
                return -1;
            }
            if (a.created > b.created) {
                return 1;
            }
            return 0;
        });

        setProduct(tProduct);
    }

    useEffect(() => {
        getData();
    }, [])

    if (loading) {
        return (
            <LoadSpin space />
        );
    }

    return (
        <div className='product'>
            <div className='mainPadding' style={{ marginTop: 40, marginBottom: 40 }}>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ fontWeight: 500, fontSize: 20, color: '#000000' }}>
                            Бүтээгдэхүүн
                        </div>
                        <div style={{ fontWeight: 400, fontSize: 14, color: '#000000' }}>
                            Нийт {product?.length} бүтээгдэхүүн
                        </div>
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <List
                            grid={{
                                gutter: 16,
                                xs: 1,
                                sm: 2,
                                md: 2,
                                lg: 3,
                                xl: 4,
                                xxl: 4,
                            }}
                            locale={{
                                emptyText: "Мэдээлэл байхгүй"
                            }}
                            dataSource={product}
                            renderItem={item => (
                                <List.Item>
                                    <Product data={item} />
                                </List.Item>
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Products;