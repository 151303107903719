import React, { useEffect, useState } from 'react';
import Title from '../../Components/Title/Title';
import { List, Row, Col, Space, Divider } from 'antd';
import { equalTo, get, getDatabase, orderByChild, query, ref } from 'firebase/database';
import { firebaseApp } from '../../API/firebaseApp';
import { checkUserAuth } from '../../Const/SharedFunc';
import ListHeader from '../../Components/ListHeader/ListHeader';
import ListText from '../../Components/ListText/ListText';
import { useSearchParams } from 'react-router-dom';
import TitleMid from '../../Components/TitleMid/TitleMid';
import TitleGray from '../../Components/TitleGray/TitleGray';
import Number from '../../Components/Base/Number/Number';
import { LoadScreen } from '../../Components/Base/LoadScreen/LoadScreen';
import successIcon from '../../Images/success.gif';
import noImage from '../../Images/noImage.png';

const Orders = (props) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const [data, setData] = useState([]);
    const [dataOrder, setDataOrder] = useState({});
    const [loading, setLoading] = useState(false);
    const [orderId, setOrderId] = useState();
    const [userAuth, setUserAuth] = useState({});

    const getData = async () => {

        let tUserAuth = checkUserAuth();
        let tData = [];

        if (tUserAuth?.uid) {
            setLoading(true);
            await get(query(ref(getDatabase(firebaseApp), "order"), orderByChild("uid"), equalTo(tUserAuth.uid))).then(response => {
                let resp = response.toJSON();
                if (resp) {
                    Object.keys(resp)?.map(key => {
                        let tItem = [];
                        if (resp[key].item) {
                            Object.keys(resp[key].item)?.map(itemkey => {
                                tItem.push(resp[key].item[itemkey]);
                            });
                        }
                        tData.push({ orderid: key, ...resp[key], item: tItem });
                    });
                }
            });
            setLoading(false);
        }

        tData?.sort((a, b) => {
            if (a.created > b.created) {
                return -1;
            }
            if (a.created < b.created) {
                return 1;
            }
            return 0;
        });
        setData(tData);
        setUserAuth(tUserAuth);
    }

    useEffect(() => {
        getData();
    }, []);


    const getDataOrder = async (pOrderId) => {
        if (pOrderId) {
            setLoading(true);

            let tDataOrder = {};
            await get(ref(getDatabase(firebaseApp), "order/" + pOrderId)).then(response => {
                let resp = response.toJSON();
                if (resp) {
                    let tItem = [];
                    if (resp.item) {
                        Object.keys(resp.item)?.map(itemkey => {
                            tItem.push(resp.item[itemkey]);
                        });
                    }
                    tDataOrder = { orderid: pOrderId, ...resp, item: tItem };
                }
            }).catch(() => {
                setLoading(false);
            });

            setDataOrder(tDataOrder);
            setLoading(false);
        }
    }

    useEffect(() => {
        let tOrderId = null;
        let tDataOrder = {};

        if (searchParams.get("orderid")) {
            tOrderId = searchParams.get("orderid");
            let tIndex = data?.map(r => r.orderid).indexOf(tOrderId);
            if (tIndex >= 0) {
                tDataOrder = data[tIndex];
            }
        }

        setOrderId(tOrderId);
        setDataOrder(tDataOrder);

        if (tOrderId && !tDataOrder?.refno) {
            getDataOrder(tOrderId);
        }

    }, [searchParams])


    const onOrderDtl = (pId) => {
        setSearchParams({
            orderid: pId
        });
    }

    if (loading) {
        return (
            <LoadScreen />
        );
    }

    if (orderId) {
        return (
            <div style={{ background: '#FFFFFF', border: '1px solid #DADADA', padding: 16 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <TitleMid value={"Захиалгын дугаар"} />
                        <Title value={dataOrder?.refno} />
                    </div>
                    <div>
                        <Title value={"Захиалга бүртгэсэн"} />
                        <TitleMid value={dataOrder?.created} />
                    </div>
                </div>
                {searchParams.get("payment") && (
                    <div>
                        <div style={{ marginTop: 16, marginBottom: 32, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ marginBottom: 16 }}>
                                <img src={successIcon} style={{ width: 60, height: 60 }} />
                            </div>
                            <div style={{ fontWeight: 400, fontSize: 14, color: "#FFFFFF", padding: "4px 16px", background: '#F8A50C', borderRadius: 14, textAlign: 'center' }}>
                                <span style={{ fontWeight: 500 }}>{"Таны захиалга амжилттай бүртгэгдлээ."}</span> <br /> {"Төлбөр төлөгдсөний дараа таны захиалга баталгаажихыг анхаарна уу."}
                            </div>
                        </div>
                        <div style={{ background: '#F9F9F9', marginTop: 16, marginBottom: 16, padding: 16 }}>
                            <TitleMid value={"Дансаар төлөх"} />
                            <div style={{ marginTop: 16 }}>
                                <Row gutter={[16, 16]}>
                                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
                                        <TitleGray value={"Дансны дугаар:"} />
                                        <div style={{ marginTop: 4 }}>
                                            <TitleMid value={"5306421052 /ХААН банк/"} />
                                        </div>
                                    </Col>
                                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
                                        <TitleGray value={"Хүлээн авагч:"} />
                                        <div style={{ marginTop: 4 }}>
                                            <TitleMid value={"Тэмүүлэн"} />
                                        </div>
                                    </Col>
                                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
                                        <TitleGray value={"Захиалгын дүн:"} />
                                        <div style={{ marginTop: 4, display: 'flex' }}>
                                            <TitleMid value={<Number value={dataOrder?.totalprice + dataOrder?.delivery} symbol="₮" />} />
                                        </div>
                                    </Col>
                                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
                                        <TitleGray value={"Гүйлгээний утга:"} />
                                        <div style={{ marginTop: 4 }}>
                                            <TitleMid value={(userAuth?.phoneNumber ? userAuth.phoneNumber.substring(4) : "") + " " + dataOrder?.refno} />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                )}
                <div style={{ marginTop: 45, marginRight: 24 }}>
                    <Row style={{ width: '100%' }} gutter={[16, 16]}>
                        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 0 }} lg={{ span: 0 }} xl={{ span: 3 }} xxl={{ span: 3 }}></Col>
                        <Col xs={{ span: 10 }} sm={{ span: 10 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }} xxl={{ span: 10 }} >
                            <ListHeader value={"Бүтээгдэхүүн"} />
                        </Col>
                        <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }} xxl={{ span: 3 }}>
                            <ListHeader value={"Үнэ"} />
                        </Col>
                        <Col xs={{ span: 2 }} sm={{ span: 2 }} md={{ span: 2 }} lg={{ span: 2 }} xl={{ span: 2 }} xxl={{ span: 3 }}>
                            <div style={{ textAlign: 'right' }}>
                                <ListHeader value={"Тоо"} />
                            </div>
                        </Col>
                        <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }} xxl={{ span: 4 }}>
                            <div style={{ textAlign: 'right' }}>
                                <ListHeader value={"Нийт"} />
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <List
                        itemLayout='horizontal'
                        dataSource={dataOrder?.item}
                        locale={{
                            emptyText: "Мэдээлэл байхгүй"
                        }}
                        renderItem={(item, index) => {
                            return (
                                <List.Item>
                                    <Row gutter={[16, 16]} align='middle' style={{ width: '100%', marginTop: index == 0 ? 0 : 16, marginBottom: (index + 1 == data?.item?.length) ? 0 : 16 }}>
                                        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 0 }} lg={{ span: 0 }} xl={{ span: 3 }} xxl={{ span: 3 }}>
                                            {item?.img ? (
                                                <img style={{ width: 60, height: 60, border: '1px solid #E7E7E7' }} src={item.img} alt="" />
                                            ) : (
                                                <img style={{ width: 60, height: 60, border: '1px solid #E7E7E7' }} src={noImage} alt="" />
                                            )}
                                        </Col>
                                        <Col xs={{ span: 10 }} sm={{ span: 10 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }} xxl={{ span: 10 }}>
                                            <div className='fontMidV2' style={{ fontWeight: 400, color: '#212121' }}>
                                                {item.name} {item.producttypename && ("/" + item.producttypename + "/")}
                                            </div>
                                        </Col>
                                        <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }} xxl={{ span: 3 }}>
                                            <div className='fontMidV2' style={{ fontWeight: 400, color: '#212121' }}>
                                                <Number value={item.price} symbol="₮" />
                                            </div>
                                        </Col>
                                        <Col xs={{ span: 2 }} sm={{ span: 2 }} md={{ span: 2 }} lg={{ span: 2 }} xl={{ span: 2 }} xxl={{ span: 3 }}>
                                            <div className='fontMidV2' style={{ fontWeight: 600, color: '#212121' }}>
                                                <Number value={item.qty} />
                                            </div>
                                        </Col>
                                        <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }} xxl={{ span: 4 }}>
                                            <div className='fontMidV2' style={{ fontWeight: 600, color: '#212121' }}>
                                                <Number value={parseFloat((parseFloat(item.price) * parseInt(item.qty)).toFixed(2))} symbol="₮" />
                                            </div>
                                        </Col>
                                    </Row>
                                </List.Item>
                            );
                        }}
                    />
                    <Divider />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Space>
                        <TitleMid value={"Төлөв:"} />
                        <Title value={dataOrder?.statusname} />
                    </Space>
                    <Space>
                        <TitleMid value={"Нийт дүн:"} />
                        <Title value={<Number value={dataOrder?.totalprice} symbol="₮" />} />
                    </Space>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Title value={"Миний захиалгууд"} />
            <div>
                <List
                    itemLayout='horizontal'
                    dataSource={data}
                    locale={{
                        emptyText: "Мэдээлэл байхгүй"
                    }}
                    renderItem={(item) => {
                        return (
                            <div
                                onClick={() => onOrderDtl(item.orderid)}
                                style={{ height: 100, background: '#FFFFFF', border: '1px solid #DADADA', padding: 16, marginTop: 24, display: 'flex', alignItems: 'center', cursor: 'pointer', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)' }}>
                                <Row style={{ width: '100%' }} gutter={[16, 16]}>
                                    <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 5 }} xxl={{ span: 5 }} >
                                        <ListHeader value="Огноо" />
                                        <div style={{ marginTop: 4 }}>
                                            <ListText value={item.created} />
                                        </div>
                                    </Col>
                                    <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 5 }} xxl={{ span: 5 }}>
                                        <ListHeader value={"Захиалгын дугаар"} />
                                        <div style={{ marginTop: 4 }}>
                                            <ListText value={item.refno} />
                                        </div>
                                    </Col>
                                    <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 5 }} xxl={{ span: 5 }}>
                                        <ListHeader value={"Төлөв"} />
                                        <div style={{ marginTop: 4 }}>
                                            <ListText value={item.statusname} />
                                        </div>
                                    </Col>
                                    <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 0 }} lg={{ span: 0 }} xl={{ span: 4 }} xxl={{ span: 0 }}>
                                        {item.item?.length > 0 ? (<img style={{ width: 64, height: 64, border: '1px solid #E7E7E7' }} src={item.item[0].img} />) : null}
                                    </Col>
                                    <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 0 }} lg={{ span: 0 }} xl={{ span: 0 }} xxl={{ span: 4 }}>
                                        <Space>
                                            {item.item?.length > 0 ? (<img style={{ width: 64, height: 64, border: '1px solid #E7E7E7' }} src={item.item[0].img} />) : null}
                                            {item.item?.length > 1 ? (<img style={{ width: 64, height: 64, border: '1px solid #E7E7E7' }} src={item.item[1].img} />) : null}
                                            {item.item?.length > 2 ? (<img style={{ width: 64, height: 64, border: '1px solid #E7E7E7' }} src={item.item[2].img} />) : null}
                                        </Space>
                                    </Col>
                                    <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 5 }} xxl={{ span: 5 }}>
                                        <div style={{ textAlign: 'right' }}>
                                            <ListHeader value={"Нийт дүн"} />
                                        </div>
                                        <div style={{ marginTop: 4 }}>
                                            <ListText value={<Number value={item.totalprice} symbol="₮" />} />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        );
                    }}
                />
            </div>
        </div>
    );
}

export default Orders;