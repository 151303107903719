import React from 'react';
import Slider from "react-slick";

const ProductImages = (props) => {


    const settings = {
        dots: true,
        speed: 500,
        slidesToScroll: 4,
        slidesToShow: 4,
        infinite: false,
        responsive: [
            {
                breakpoint: 481,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 577,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 769,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 993,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 1201,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const onChange = (pImgUrl) => {
        if (props.onChange) {
            props.onChange(pImgUrl);
        }
    }

    return (
        <div className='customSlickLeft' style={{ width: 500 }}>
            <Slider {...settings}>
                {props.data?.map((r, index) => {
                    return (
                        <div key={index} onClick={() => onChange(r)}>
                            <img style={{ width: 100, height: 100, border: '1px solid #E7E7E7', imageRendering: 'pixelated', cursor: 'pointer' }} src={r} alt='' />
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
}

export default ProductImages;