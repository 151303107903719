import React from 'react';

const TitleGray = (props) => {
    return (
        <div style={{ fontWeight: 400, fontSize: 12, color: '#767676' }}>
            {props.value}
        </div>
    );
}

export default TitleGray;