import React from 'react';

const Exit = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.0001 20.4C15.3314 20.4 15.6001 20.1313 15.6001 19.8C15.6001 19.4687 15.3314 19.2 15.0001 19.2H7.2001C5.87461 19.2 4.8001 18.1255 4.8001 16.8V7.2C4.8001 5.87451 5.87461 4.8 7.2001 4.8H15.0001C15.3314 4.8 15.6001 4.53137 15.6001 4.2C15.6001 3.86863 15.3314 3.6 15.0001 3.6H7.2001C5.21188 3.6 3.6001 5.21178 3.6001 7.2V16.8C3.6001 18.7883 5.21188 20.4 7.2001 20.4H15.0001ZM16.3758 7.37574C16.6101 7.14141 16.9901 7.14141 17.2244 7.37574L21.4244 11.5757C21.6587 11.8101 21.6587 12.19 21.4244 12.4243L17.2244 16.6243C16.9901 16.8586 16.6101 16.8586 16.3758 16.6243C16.1415 16.39 16.1415 16.01 16.3758 15.7757L19.5516 12.6H9.0001C8.66873 12.6 8.4001 12.3313 8.4001 12C8.4001 11.6686 8.66873 11.4 9.0001 11.4H19.5516L16.3758 8.22426C16.1415 7.98995 16.1415 7.61005 16.3758 7.37574Z" fill="#212121" />
        </svg>
    );
}

export default Exit;