import React, { useEffect, useState } from 'react';
import { Dropdown } from '@fluentui/react';
import { Shimmer } from '@fluentui/react';

const FieldDropdown = (props) => {

    const [value, setValue] = useState();

    const onChanged = (p_value) => {
        props.onChanged(p_value.key, p_value.text, props.keyfield, props.keyfieldname);
    }

    useEffect(() => {
        let tValue = null;
        if (props?.defaultValue) {
            tValue = props?.defaultValue;
        }
        setValue(tValue);
    }, [props.defaultValue]);

    return (
        <div style={{ marginTop: 8 }}>
            {props.loading ? (
                <div>
                    <Shimmer />
                    <Shimmer />
                </div>
            ) : (
                <Dropdown
                    options={props.data ? props.data : []}
                    selectedKey={value}
                    disabled={props.disable ? true : false}
                    onChanged={onChanged}
                    errorMessage={(props.required && !value?.toString() && props.checkreq && props.data?.length > 0 && !props.disable) ? (props.title + " талбарын утга хоосон байна.") : null}
                    styles={{
                        title: {
                            border: '1px solid #DDDDDD',
                            height: 48,
                            borderRadius: 24,
                            padding: '8px 16px'
                        },
                        caretDownWrapper: {
                            marginTop: 8
                        }
                    }}
                    placeholder={props.placeholder}
                />
            )}
        </div>
    );
}

export default FieldDropdown;