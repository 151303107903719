import React, { useState, useEffect } from 'react';
import { Color, ConstCss } from '../../const/Const';
import { ReactComponent as DropdownIcon } from '../../svg/dropdownLine.svg';
import { Dropdown } from 'antd';

const PageFilter = (props) => {

    const [displayValue, setDisplayValue] = useState();
    const [menuItems, setMenuItems] = useState([]);

    useEffect(() => {
        let tMenuItems = [];
        if (props.data?.length > 0) {
            props.data?.map(r => {
                tMenuItems.push({
                    key: r.key,
                    label: (
                        <div onClick={() => onChange(r.key, r.text)}>
                            {r.text} {r.description && (<div style={{ fontSize: 12, color: Color.gray }}>{r.description}</div>)}
                        </div>
                    )
                });

                if (r.divider) {
                    tMenuItems.push({
                        type: 'divider'
                    });
                }
            })
        }

        setMenuItems(tMenuItems);

    }, [props.data]);

    useEffect(() => {
        let tDisplayValue = null;
        if (props?.value && props?.data?.length > 0) {
            let tIndex = props.data.map(r => r.key).indexOf(props.value);
            if (tIndex >= 0) {
                tDisplayValue = props.data[tIndex].text;
            }
        }
        setDisplayValue(tDisplayValue);
    }, [props.value]);

    const onChange = (pKey, pText) => {
        if (props.onChange) {
            props.onChange(pKey, pText);
        }
    }

    return (
        <Dropdown
            menu={{
                items: menuItems
            }}
            trigger={['click']}
        >
            <div style={{ ...ConstCss.flexCenter, cursor: 'pointer' }}>
                <div style={{ fontSize: 20, color: Color.black }}>
                    {displayValue} {props.dataCount ? `(${props.dataCount})` : null}
                </div>
                <div style={{ marginTop: 11, marginLeft: 8 }}>
                    <DropdownIcon />
                </div>
            </div>
        </Dropdown>
    );
}

export default PageFilter;