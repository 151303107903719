export const getFilter = (p_filter, p_filterparams) => {
    let filtercolumns = [];

    if (p_filter?.length > 0) {
        filtercolumns = p_filter;
    } else {
        if (p_filter?.length != 0) {
            if (p_filterparams) {
                try {
                    filtercolumns = JSON.parse(p_filterparams);
                } catch (e) {
                    filtercolumns = [];
                }
            }
        }
    }

    return filtercolumns;
}

export const searchData = (p_text, p_data) => {
    return p_data?.filter(r => {
        let tValue = Object.keys(r).map(key => {
            return r[key];
        })?.join('')?.toLowerCase();

        if (tValue?.indexOf(p_text?.toLowerCase()) >= 0) {
            return true;
        } else {
            return false;
        }
    });
}