import React from 'react';

const NewsIcon = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.7499 2C18.9407 2 19.9155 2.92516 19.9947 4.09595L19.9999 4.25V19.7481C19.9999 20.9389 19.0747 21.9137 17.9039 21.9929L17.7499 21.9981H6.25C5.05914 21.9981 4.08436 21.0729 4.00519 19.9021L4 19.7481V4.25C4 3.05914 4.92516 2.08436 6.09595 2.00519L6.25 2H17.7499ZM17.7499 3.5H6.25C5.8703 3.5 5.55651 3.78215 5.50685 4.14823L5.5 4.25V19.7481C5.5 20.1278 5.78215 20.4416 6.14823 20.4912L6.25 20.4981H17.7499C18.1296 20.4981 18.4434 20.2159 18.493 19.8498L18.4999 19.7481V4.25C18.4999 3.8703 18.2177 3.55651 17.8516 3.50685L17.7499 3.5ZM6.99994 15.749C6.99994 15.3348 7.33572 14.999 7.74994 14.999H16.2499C16.6642 14.999 16.9999 15.3348 16.9999 15.749C16.9999 16.1632 16.6642 16.499 16.2499 16.499H7.74994C7.33572 16.499 6.99994 16.1632 6.99994 15.749ZM6.99994 7.74903C6.99994 7.33482 7.33572 6.99903 7.74994 6.99903H16.2499C16.6642 6.99903 16.9999 7.33482 16.9999 7.74903C16.9999 8.16325 16.6642 8.49903 16.2499 8.49903H7.74994C7.33572 8.49903 6.99994 8.16325 6.99994 7.74903ZM6.99994 11.749C6.99994 11.3348 7.33572 10.999 7.74994 10.999H16.2499C16.6642 10.999 16.9999 11.3348 16.9999 11.749C16.9999 12.1632 16.6642 12.499 16.2499 12.499H7.74994C7.33572 12.499 6.99994 12.1632 6.99994 11.749Z" fill={props.active ? "#F8A50C" : "#FFFFFF"} />
        </svg>
    );
}

export default NewsIcon;