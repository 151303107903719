import React, { useState, useEffect, useRef } from 'react';
import { Color, ConstCss } from '../../const/Const';
import { ReactComponent as Divider } from '../../svg/verticalDividerGray.svg';
import { TextField } from '@fluentui/react';
import { searchData } from '../../SharedFunc/FilterAndSearch.js';
import PageTitle from '../PageTitle/PageTitle';
import PageFilter from '../PageFilter/PageFilter';
import FieldFilter from '../FieldFilter/FieldFilter';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import moment from 'moment';
import SearchIcon from '../../svg/IconClass/SearchIcon';
import ExitIcon from '../../svg/IconClass/ExitIcon';
import GridFilter from '../GridFilter/GridFilter';

const PageHeader = (props) => {

    const searchFieldRef = useRef();
    const filterField = useRef();

    const [data, setData] = useState([]);

    const [searchValue, setSearchValue] = useState();
    const [showFilter, setShowFilter] = useState(false);

    const onSearch = (p_value) => {

        setSearchValue(p_value.target.value);

        let filteredData = [...data];
        if (p_value?.target?.value && data?.length > 0) {
            filteredData = searchData(p_value?.target?.value, data);
        }

        if (props.onSearch) {
            props.onSearch(filteredData);
        }

    };

    const onChangeFilter = () => {
        setShowFilter(true);
    }

    useEffect(() => {
        let tData = [];
        if (props.data) {
            tData = props.data;
        }
        setData(tData);

    }, [props.data]);

    useEffect(() => {
        if (showFilter) {
            searchFieldRef?.current?.focus();
        }
    }, [showFilter]);

    const onGridFilterChange = (p_gridfiltervalue) => {
        if (props.onGridFilterChange) {
            props.onGridFilterChange(p_gridfiltervalue);
        }
    }

    const onExit = () => {
        let filteredData = [...data];
        if (props.onSearch) {
            props.onSearch(filteredData);
        }
        if (props.onGridFilterChange) {
            props.onGridFilterChange([]);
        }
        setShowFilter(r => !r);
    }

    return (
        <div>
            <div style={{
                height: 50,
                width: '100%',
                backgroundColor: Color.grayBackground,
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <div style={{ ...ConstCss.flexCenter, marginLeft: 16 }}>

                    {!props.showLeftFilter && (
                        <div style={{ marginRight: 16 }}>
                            <PageTitle>{props.title} {(props.loading != true && props.dataCount) ? ("(" + props.dataCount + ")") : null}</PageTitle>
                        </div>
                    )}
                    {props.showLeftFilter && (
                        <div style={{ marginRight: 16 }}>
                            <PageFilter onChange={props.changeFilter} value={props.filterValue} data={props.filterData} dataCount={props.dataCount} />
                        </div>
                    )}
                    {props.buttonTitle && (
                        <div style={{ height: 28 }}>
                            <Divider />
                        </div>
                    )}
                    <div style={{ marginLeft: 16 }}>
                        {props.buttonTitle && (<ButtonIcon text={props.buttonTitle} iconName='Add' onClick={props.onButtonClick} />)}
                    </div>
                    {props.addButton && (
                        <div style={{ marginLeft: props?.addButtonSpace ? props?.addButtonSpace : 16 }}>
                            {props.addButton}
                        </div>
                    )}
                </div>
                <div style={{ ...ConstCss.flexCenter, marginRight: 16 }}>
                    {props.addRightButton ? (props.addRightButton) : null}
                    {props.data ? (
                        <>
                            <div onClick={onChangeFilter} style={{ marginLeft: 8 }} ref={filterField}>
                                <FieldFilter />
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
            {showFilter ? (
                <div style={{
                    height: 50,
                    width: '100%',
                    backgroundColor: Color.grayHeaderBack,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 16 }}>
                        <SearchIcon />
                        <TextField componentRef={searchFieldRef} onChange={onSearch} style={{ backgroundColor: Color.grayHeaderBack, minWidth: 400 }} borderless placeholder="Хайх..." value={searchValue} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 16 }}>
                        <GridFilter data={props.gridFilter} onGridFilterChange={onGridFilterChange} />
                        <div onClick={onExit} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                            <ExitIcon />
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
}
export default PageHeader;