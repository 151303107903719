import React from 'react';
import moment from 'moment';

export const checkUserAuth = () => {

    let tResp = null;

    if (localStorage.getItem("userAuth")) {
        let tUserAuth = JSON.parse(localStorage.getItem("userAuth"));
        if (moment(tUserAuth?.stsTokenManager.expirationTime).toDate() > moment().toDate()) {
            tResp = tUserAuth;
        }
    }

    return tResp;
}

export const getCart = () => {
    if (localStorage.getItem("cart")) {
        return JSON.parse(localStorage.getItem("cart"));
    } else {
        return [];
    }
}


export const updateCart = (itemid, qty) => {
    let tCart = [];
    if (localStorage.getItem("cart")) {
        tCart = JSON.parse(localStorage.getItem("cart"));
    }

    let tIndex = tCart?.map(r => r.id).indexOf(itemid);

    if (tIndex >= 0) {
        tCart[tIndex].qty = parseInt(qty);
    }

    if (tCart?.length > 0) {
        localStorage.setItem("cart", JSON.stringify(tCart));
    } else {
        localStorage.removeItem("cart");
    }

    return tCart;
}


export const delCart = (itemid) => {
    let tCart = [];
    if (localStorage.getItem("cart")) {
        tCart = JSON.parse(localStorage.getItem("cart"));
    }

    let tIndex = tCart?.map(r => r.id).indexOf(itemid);

    if (tIndex >= 0) {
        tCart.splice(tIndex, 1);
    }

    if (tCart?.length > 0) {
        localStorage.setItem("cart", JSON.stringify(tCart));
    } else {
        localStorage.removeItem("cart");
    }

    return tCart;
}

export const addAddress = (pData) => {
    localStorage.setItem("address", JSON.stringify(pData));
}

export const getAddress = () => {
    if (localStorage.getItem("address")) {
        return JSON.parse(localStorage.getItem("address"));
    } else {
        return null;
    }
}