import React, { useState, useEffect } from 'react';
import GridFilterField from '../GridFilterField/GridFilterField';
import { useSearchParams } from 'react-router-dom';

const GridFilter = (props) => {

    const [data, setData] = useState([]);
    const [value, setValue] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [filterData, setFilterData] = useState([]);

    useEffect(() => {
        let tData = [];
        if (props?.data?.length > 0) {
            tData = props.data;
        }

        let filtervalue = searchParams.get('filter');
        let tFilterData = [];
        if (filtervalue) {
            try {
                tFilterData = JSON.parse(filtervalue);
            } catch (e) {
                tFilterData = [];
            }
        }

        setData(tData);
        setFilterData(tFilterData);
        setValue(tFilterData);
    }, [props.data]);

    const onChanged = (p_value, p_key) => {
        let tValue = [...value];
        tValue = tValue?.filter(r => r.fieldname != p_key);
        tValue = tValue?.concat(p_value);
        setValue(tValue);
        if (props.onGridFilterChange) {
            props.onGridFilterChange(tValue);
        }
    }

    return (
        <div style={{ display: 'flex' }}>
            {data?.map((r, index) => {

                let tSelectedKeys = [];
                if (filterData?.length > 0) {
                    let tFilterData = filterData?.filter(rf => rf.fieldname == r.key);
                    if (tFilterData?.length > 0) {
                        tFilterData?.map(rf => {
                            tSelectedKeys.push(rf.value);
                        });
                    }
                }

                return (
                    <div key={index} style={{ marginRight: 16 }}>
                        <GridFilterField selectedKeys={tSelectedKeys} keyfield={r.key} namefield={r.text} data={r.data} onChanged={onChanged} parentkey={r.parentid} parentvalue={value} />
                    </div>
                );
            })}
        </div>
    );
}

export default GridFilter;