import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { firebaseApp } from '../../../../API/firebaseApp';
import { getDatabase, ref, push, get, set } from "firebase/database";
import { message } from 'antd';
import { Breadcrumb } from 'antd';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Col, Row } from 'antd';
import Specifications from './Specifications';
import ProductImage from './ProductImage';
import ProductType from './ProductType';
import LoadSpin from '../../../../Components/Base/LoadSpin/LoadSpin';
import ButtonDefault from '../../../../Components/Base/ButtonDefault/ButtonDefault';
import FieldCheck from '../../../../Components/Base/FieldCheck/FieldCheck';
import FieldDropdown from '../../../../Components/Base/FieldDropdown/FieldDropdown';
import FieldNumber from '../../../../Components/Base/FieldNumber/FieldNumber';
import FieldText from '../../../../Components/Base/FieldText/FieldText';
import FieldTextArea from '../../../../Components/Base/FieldTextArea/FieldTextArea';
import FieldDate from '../../../../Components/Base/FieldDate/FieldDate';
import ProductColor from './ProductColor';

const ProductNew = (props) => {

    const params = useParams();
    const navigate = useNavigate();
    const [initData, setInitData] = useState({
        name: null,
        description: null,
        price: 0,
        oprice: 0,
        img: null,
        specifications: [],
        images: [],
        active: true,
        isdiscount: false,
        created: moment().format("YYYY.MM.DD HH:mm:ss")
    });
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [checkRule, setCheckRule] = useState(false);
    const [dCategory, setDCategory] = useState([]);

    const getData = async () => {
        let tData = { ...initData };
        setLoading(true);
        if (params.id) {
            await get(ref(getDatabase(firebaseApp), "product/" + params.id)).then(response => {
                let resp = response.toJSON();
                if (resp) {
                    tData = resp;

                    let tSpecifications = [];
                    if (tData?.specifications) {
                        Object.keys(tData.specifications)?.map(key => {
                            tSpecifications.push(tData.specifications[key]);
                        });
                    }
                    tData.specifications = tSpecifications;

                    let tProductType = [];
                    if (tData?.producttype) {
                        Object.keys(tData.producttype)?.map(key => {
                            tProductType.push(tData.producttype[key]);
                        });
                    }
                    tData.producttype = tProductType;

                    let tImages = [];
                    if (tData?.images) {
                        Object.keys(tData.images)?.map(key => {
                            tImages.push(tData.images[key]);
                        });
                    }
                    tData.images = tImages;
                }
            });
        }

        let tDCategory = [];
        await get(ref(getDatabase(firebaseApp), "category")).then(response => {
            let resp = response.toJSON();
            if (resp) {
                Object.keys(resp)?.map(key => {
                    if (resp[key]?.active) {
                        tDCategory.push({ key: key, text: resp[key]?.name });
                    }
                });
            }
        });

        tDCategory.sort((a, b) => {
            if (a.created > b.created) {
                return -1;
            }
            if (a.created < b.created) {
                return 1;
            }
            return 0;
        });
        setLoading(false);
        setDCategory(tDCategory)
        setData(tData);
        setCheckRule(false);
    }

    useEffect(() => {
        getData();
    }, [params.id]);

    const fieldOnChanged = async (key, text, field, fieldname) => {
        if (field) {
            let tData = { ...data };
            tData[field] = key;
            if (fieldname) {
                tData[fieldname] = text;
            }
            setData(tData);
        }
    }

    const onSave = async () => {

        let tData = { ...data };
        setCheckRule(true);
        let success = false;
        let tId = "";

        let vRule = true;

        if (!tData.name
            || !tData.categoryid) {
            vRule = false;
        }

        if (!tData.price) {
            vRule = false;
        }

        if (vRule) {
            setLoadingSave(true);

            if (tData?.images?.length > 0) {
                tData.img = tData?.images[0].img;
            }

            if (params.id && params.id != -1) {
                await set(ref(getDatabase(firebaseApp), "product/" + params.id), tData).then(response => {
                    success = true;
                    message.success("Амжилттай.");
                });
            } else {
                tData.created = moment().format("YYYY.MM.DD HH:mm:ss");
                await push(ref(getDatabase(firebaseApp), "product"), tData).then(response => {
                    tId = response.key;
                    success = true;
                    message.success("Амжилттай.");
                });
            }
            setLoadingSave(false);
            if (success) {
                navigate("/admin/product");
            }
        }
    }

    return (
        <div style={{ padding: 16 }}>
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/admin/product">Бүтээгдэхүүний жагсаалт</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {params.id}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div style={{ marginTop: 24, fontWeight: 600, fontSize: 20 }}>
                Бүтээгдэхүүний дэлгэрэнгүй
            </div>
            <div style={{ marginTop: 21 }}>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <FieldText required loading={loading} title="Бүтээгдэхүүний нэр" onChanged={fieldOnChanged} keyfield='name' defaultValue={data.name} checkreq={checkRule} />
                    </Col>
                    <Col span={6}>
                        <FieldDropdown required loading={loading} title="Категори" onChanged={fieldOnChanged} keyfield='categoryid' keyfieldname='categoryname' defaultValue={data.categoryid} data={dCategory} checkreq={checkRule} />
                    </Col>
                    <Col span={6}>
                        <FieldDate loading={loading} title="Дуусах хугацаа" onChanged={fieldOnChanged} keyfield='expirydate' defaultValue={data.expirydate} />
                    </Col>
                </Row>
            </div>
            <div style={{ marginTop: 16 }}>
                <Row gutter={[16, 16]}>
                    <Col span={6}>
                        <FieldNumber required loading={loading} title="Үнэ" onChanged={fieldOnChanged} keyfield='price' defaultValue={data.price} checkreq={checkRule} />
                    </Col>
                    <Col span={6}>
                        <FieldCheck loading={loading} title="Төлөв" onChanged={fieldOnChanged} keyfield='active' defaultValue={data.active} />
                    </Col>
                    <Col span={6}>
                        <FieldCheck loading={loading} title="Хямдралтай эсэх" onChanged={fieldOnChanged} keyfield='isdiscount' defaultValue={data.isdiscount} />
                    </Col>
                    <Col span={6}>
                        {data.isdiscount && (<FieldNumber loading={loading} title="Үндсэн үнэ" onChanged={fieldOnChanged} keyfield='oprice' defaultValue={data.oprice} />)}
                    </Col>

                </Row>
            </div>
            <div style={{ marginTop: 16 }}>
                <FieldTextArea loading={loading} title="Бүтээгдэхүүний тайлбар" onChanged={fieldOnChanged} keyfield='description' defaultValue={data.description} />
            </div>
            <div style={{ marginTop: 16 }}>
                <ProductType loading={loading} title="Хэмжээ" onChanged={fieldOnChanged} keyfield='producttype' defaultValue={data.producttype} />
            </div>
            <div style={{ marginTop: 16 }}>
                <ProductColor loading={loading} title="Өнгө" onChanged={fieldOnChanged} keyfield='productcolor' defaultValue={data.productcolor} />
            </div>
            <div style={{ marginTop: 16 }}>
                <Specifications loading={loading} title="Үзүүлэлт" onChanged={fieldOnChanged} keyfield='specifications' defaultValue={data.specifications} />
            </div>
            <div style={{ marginTop: 16 }}>
                <ProductImage loading={loading} title="Зураг" onChanged={fieldOnChanged} keyfield='images' defaultValue={data.images} />
            </div>
            <div style={{ marginTop: 16 }}>
                {loadingSave ? (
                    <LoadSpin />
                ) : (
                    <ButtonDefault onClick={onSave} text="Хадгалах" />
                )}
            </div>
        </div>
    );
}

export default ProductNew;