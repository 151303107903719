import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ClockIcon } from '../../Svg/clock.svg';
import { ReactComponent as VisitIcon } from '../../Svg/visit.svg';

const NewsList = (props) => {

    const navigate = useNavigate();

    const onDtl = (pId) => {
        navigate("/news/" + pId);
    }

    return (
        <div style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }} onClick={() => onDtl(props?.data?.id)}>
            <img style={{ width: props?.small ? 140 : 180, height: props?.small ? 140 : 180 }} src={props?.data?.img} />
            <div style={{ marginLeft: 25 }}>
                <div style={{ fontWeight: 400, fontSize: props?.small ? 16 : 18, color: "#212121" }}>{props?.data?.title}</div>
                {!props?.small && (<div style={{ marginTop: 8, fontWeight: 400, fontSize: 14, color: '#767676' }}>{props?.data?.bodymid}</div>)}
                <div style={{ display: 'flex', marginTop: 16, alignItems: 'center' }}>
                    <div style={{ marginTop: 4 }}><ClockIcon /></div>
                    <div style={{ marginLeft: 4, fontWeight: 400, fontSize: 14, color: '#767676' }}>{props?.data?.created}</div>
                    <div style={{ marginLeft: 16, marginTop: 4 }}><VisitIcon /></div>
                    <div style={{ marginLeft: 4, fontWeight: 400, fontSize: 14, color: '#767676' }} >{props?.data?.visitcount}</div>
                </div>
            </div>
        </div>
    );
}

export default NewsList;