import React from 'react';

const AdminTitle = (props) => {
    return (
        <div style={{ fontWeight: 600, fontSize: 20, color: "#212121" }}>
            {props?.value}
        </div>
    );
}

export default AdminTitle;