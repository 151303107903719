import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { ReactComponent as UserIcon } from '../../../Svg/dashboard/user.svg';
import { ReactComponent as ProdutIcon } from '../../../Svg/dashboard/product.svg';
import { ReactComponent as OrderIcon } from '../../../Svg/dashboard/order.svg';
import { ReactComponent as SalesIcon } from '../../../Svg/dashboard/sales.svg';
import { get, getDatabase, query, ref } from 'firebase/database';
import { firebaseApp } from '../../../API/firebaseApp';
import AdminTitle from '../../../Components/AdminTitle/AdminTitle';
import CartChart from '../../../Components/CartChart/CartChart';
import { ReactComponent as Chart1 } from '../../../Svg/admin/chartIcon/chart1.svg';
import { ReactComponent as Chart2 } from '../../../Svg/admin/chartIcon/chart2.svg';
import { ReactComponent as Chart3 } from '../../../Svg/admin/chartIcon/chart3.svg';
import { ReactComponent as Chart4 } from '../../../Svg/admin/chartIcon/chart4.svg';
import { ReactComponent as Chart5 } from '../../../Svg/admin/chartIcon/chart5.svg';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Number from '../../../Components/Base/Number/Number';
import { LoadScreen } from '../../../Components/Base/LoadScreen/LoadScreen';

const Dashboard = (props) => {

    const [userData, setUserData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [orderStatusCount, setOrderStatusCount] = useState({});
    const [salesPrice, setSalesPrice] = useState(0);
    const [loading, setLoading] = useState(false);

    const [dataChart, setDataChart] = useState([
        {
            pmonth: 'Page A',
            price: 2400,
        },
        {
            pmonth: 'Page A',
            price: 2400,
        }
    ]);

    const getData = async () => {

        let tUserData = [];
        let tProductData = [];
        let tOrderData = [];
        let tSalesPrice = 0;
        let tDataChart = [];

        setLoading(true);
        await get(ref(getDatabase(firebaseApp), "user")).then(response => {
            let resp = response.toJSON();
            if (resp) {
                Object.keys(resp)?.map(key => {
                    tUserData.push({ id: key, ...resp[key] });
                });
            }
        }).catch(response => {
            setLoading(false);
        });

        await get(ref(getDatabase(firebaseApp), "product")).then(response => {
            let resp = response.toJSON();
            if (resp) {
                Object.keys(resp)?.map(key => {
                    if (resp[key].active) {
                        tProductData.push({ id: key, ...resp[key] });
                    }
                });
            }
        }).catch(response => {
            setLoading(false);
        });

        await get(ref(getDatabase(firebaseApp), "order")).then(response => {
            let resp = response.toJSON();
            if (resp) {
                Object.keys(resp)?.map(key => {
                    tOrderData.push({ id: key, ...resp[key] });
                    if (!isNaN(resp[key]?.totalprice) && resp[key]?.status > 0 && resp[key]?.status != 6) {
                        tSalesPrice += parseFloat(resp[key]?.totalprice);
                    }
                });
            }
        }).catch(response => {
            setLoading(false);
        });

        tOrderData?.map(r => {
            if (r?.status > 0 && r?.status != 6) {
                let tPMonth = r.created?.substr(0, 7);
                let tIndex = tDataChart.map(r => r.pmonth).indexOf(tPMonth);
                if (tIndex >= 0) {
                    tDataChart[tIndex].price = parseFloat(tDataChart[tIndex].price) + parseFloat(r.totalprice);
                } else {
                    tDataChart.push({
                        pmonth: tPMonth,
                        price: parseFloat(r.totalprice)
                    });
                }
            }
        });

        setLoading(false);

        setUserData(tUserData);
        setProductData(tProductData);
        setOrderData(tOrderData);
        setSalesPrice(tSalesPrice);
        setOrderStatusCount({
            "0": tOrderData?.filter(r => r.status == 0)?.length,
            "1": tOrderData?.filter(r => r.status == 1)?.length,
            "2": tOrderData?.filter(r => r.status == 2)?.length,
            "3": tOrderData?.filter(r => r.status == 5)?.length,
            "4": tOrderData?.filter(r => r.status == 6)?.length
        });
        setDataChart(tDataChart);
    }

    useEffect(() => {
        getData();
    }, []);

    if (loading) {
        return (
            <LoadScreen />
        );
    }

    return (
        <div style={{ padding: '24px 16px' }}>
            <AdminTitle value="Хянах самбар" />
            <div style={{ width: '100%', marginTop: 16 }}>
                <Row gutter={[16, 16]}>
                    <Col span={6}>
                        <div style={{ background: '#0694A2', borderRadius: 4, height: 111, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <div>
                                <UserIcon />
                            </div>
                            <div style={{ fontWeight: 500, fontSize: 14, color: "#FFFFFF" }}>
                                Нийт хэрэглэгч
                            </div>
                            <div style={{ marginTop: 4, fontWeight: 700, fontFamily: 'Montserrat', fontSize: 20, color: "#FFFFFF" }}>
                                {userData?.length}
                            </div>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div style={{ background: '#0E9F6E', borderRadius: 4, height: 111, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <div>
                                <ProdutIcon />
                            </div>
                            <div style={{ fontWeight: 500, fontSize: 14, color: "#FFFFFF" }}>
                                Нийт бүтээгдэхүүн
                            </div>
                            <div style={{ marginTop: 4, fontWeight: 700, fontFamily: 'Montserrat', fontSize: 20, color: "#FFFFFF" }}>
                                {productData?.length}
                            </div>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div style={{ background: '#0EA5E9', borderRadius: 4, height: 111, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <div>
                                <OrderIcon />
                            </div>
                            <div style={{ fontWeight: 500, fontSize: 14, color: "#FFFFFF" }}>
                                Нийт захиалга
                            </div>
                            <div style={{ marginTop: 4, fontWeight: 700, fontFamily: 'Montserrat', fontSize: 20, color: "#FFFFFF" }}>
                                {orderData?.length}
                            </div>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div style={{ background: '#0EA5E9', borderRadius: 4, height: 111, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <div>
                                <SalesIcon />
                            </div>
                            <div style={{ fontWeight: 500, fontSize: 14, color: "#FFFFFF" }}>
                                Нийт борлуулалт
                            </div>
                            <div style={{ marginTop: 4, fontWeight: 700, fontFamily: 'Montserrat', fontSize: 20, color: "#FFFFFF" }}>
                                <Number value={salesPrice} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div style={{ marginTop: 24 }}>
                <AdminTitle value="Идэвхтэй захиалгууд" />
            </div>
            <div style={{ marginTop: 16 }}>
                <Row gutter={[16, 16]}>
                    <Col span={5}>
                        <CartChart icon={<Chart1 />} title="Төлбөр хүлээгдэж буй" count={orderStatusCount?.["0"]} />
                    </Col>
                    <Col span={5}>
                        <CartChart icon={<Chart2 />} title="Төлбөр баталгаажсан" count={orderStatusCount?.["1"]} />
                    </Col>
                    <Col span={5}>
                        <CartChart icon={<Chart3 />} title="Захиалга хийгдсэн" count={orderStatusCount?.["2"]} />
                    </Col>
                    <Col span={5}>
                        <CartChart icon={<Chart4 />} title="Барааг хүлээлгэж өгсөн" count={orderStatusCount?.["3"]} />
                    </Col>
                    <Col span={4}>
                        <CartChart icon={<Chart5 />} title="Цуцлагдсан" count={orderStatusCount?.["4"]} />
                    </Col>
                </Row>
            </div>
            <div style={{ width: '100%', height: 300, marginTop: 50 }}>
                <ResponsiveContainer>
                    <BarChart
                        data={dataChart}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="pmonth" />
                        <YAxis fontSize={8} />
                        <Tooltip />
                        <Legend />
                        <Bar barSize={40} dataKey="price" fill="#0EA5E9" name='Нийт үнэ' />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}

export default Dashboard;