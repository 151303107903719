import React from 'react';

const ListText = (props) => {
    return (
        <div className='fontMidV2' style={{ fontWeight: 400, color: '#212121' }}>
            {props.value}
        </div>
    );
}

export default ListText;