import React from 'react';
import { ShimmeredDetailsList, SelectionMode, DetailsListLayoutMode, DetailsRow, DetailsList } from '@fluentui/react';
import { Color } from '../../const/Const';

const GridDefaultShimmer = (props) => {

    let columns = [];
    if (props?.columns?.length > 0) {
        columns = props.columns.map(r => {
            let vRow = { ...r };
            vRow.isResizable = true;
            vRow.key = r.fieldName;

            let classNames = [];
            if (r.isBold) {
                classNames.push("row-bold");
            }
            if (r.isCapitalize) {
                classNames.push("row-capitalize")
            }

            if (classNames?.length > 0) {
                vRow.className = classNames.join(" ");
            }

            if (r.colSize) {
                if (r.colSize == 'small') {
                    vRow.maxWidth = 100;
                }
                if (r.colSize == 'mid') {
                    vRow.maxWidth = 160;
                }
            }
            if (r.minColSize) {
                if (r.minColSize == 'mid') {
                    vRow.minWidth = 220;
                    vRow.maxWidth = 260;
                } else if (r.minColSize == 'large') {
                    vRow.minWidth = 320;
                    vRow.maxWidth = 360;
                }
            }

            return vRow;
        });
    }

    if (props.loading) {
        return (
            <div style={{ marginLeft: 16, marginRight: 16 }}>
                <ShimmeredDetailsList
                    selectionMode={SelectionMode.none}
                    enableShimmer={props.loading ? true : false}
                    compact={true}
                    items={[]}
                    columns={columns}
                />
                {!props.loading && props?.data?.length <= 0 ? (
                    <div style={{ backgroundColor: Color.white, textAlign: 'center', color: Color.grayNoData, paddingBottom: 18 }}>
                        Харуулах мэдээлэлгүй байна
                    </div>
                ) : (
                    null
                )}
            </div>
        );
    }

    return (
        <div style={{ marginLeft: 16, marginRight: 16 }}>
            <DetailsList
                styles={{
                    root: {
                        overflowX: 'auto',
                        selectors: {
                            '& [role=grid]': {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                maxHeight: 'calc(100vh - 160px)'
                            },
                        },
                    },
                    headerWrapper: {
                        flex: '0 0 auto',
                    },
                    contentWrapper: {
                        flex: '1 1 auto',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }
                }}
                setKey={props.setKey ? props.setKey : null}
                selectionMode={SelectionMode.none}
                layoutMode={props.fixedColumns ? DetailsListLayoutMode.fixedColumns : DetailsListLayoutMode.justified}
                enableShimmer={props.loading ? true : false}
                compact={true}
                items={props.data ? props.data : []}
                columns={columns}
                onRenderRow={props.onRenderRow ? props.onRenderRow : null}
            />
            {!props.loading && props?.data?.length <= 0 ? (
                <div style={{ backgroundColor: Color.white, textAlign: 'center', color: Color.grayNoData, paddingBottom: 18 }}>
                    Харуулах мэдээлэлгүй байна
                </div>
            ) : (
                null
            )}
        </div>
    );
}

export default GridDefaultShimmer;