import React, { useEffect, useState } from 'react';

const CartCounter = (props) => {

    const [data, setData] = useState(0);

    useEffect(() => {
        let tData = 0;
        if (props.data?.length > 0) {
            props.data?.map(r => {
                if (r.qty) {
                    tData += parseInt(r.qty);
                }
            });
        }
        setData(tData);
    }, [props.data]);

    if (data > 0) {
        return (
            <div style={{
                position: 'absolute',
                background: '#ff0000',
                height: 18,
                width: 18,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 9,
                marginLeft: 13,
                marginTop: -4,
                color: '#FFFFFF',
                fontSize: 9
            }}>{data < 100 ? data : "..."}</div>
        );
    } else {
        return null;
    }

}

export default CartCounter;