import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { firebaseApp } from '../../../../API/firebaseApp';
import { message } from 'antd';
import ButtonDefault from '../../../../Components/Base/ButtonDefault/ButtonDefault';
import FieldText from '../../../../Components/Base/FieldText/FieldText';
import PanelBase from '../../../../Components/Base/PanelBase/PanelBase';

const SpecificationsNew = (props) => {

    const [initData, setInitData] = useState({
        id: props.id,
        name: null,
        body: null,
        created: moment().format("YYYY.MM.DD HH:mm:ss")
    });
    const [data, setData] = useState({});
    const [checkRule, setCheckRule] = useState(false);
    const [rowIndex, setRowIndex] = useState(-1);

    const getData = async () => {
        let tData = { ...initData };
        let tIndex = -1;
        if (props.id && props.data?.length > 0) {

            tIndex = props.data.map(r => r.id).indexOf(props.id);

            if (tIndex >= 0) {
                tData = props.data[tIndex];
            }
        }
        setData(tData);
        setRowIndex(tIndex);
    }

    useEffect(() => {
        getData();
    }, [props.id]);

    const fieldOnChanged = async (key, text, field, fieldname) => {
        if (field) {
            let tData = { ...data };
            tData[field] = key;
            if (fieldname) {
                tData[fieldname] = text;
            }

            setData(tData);
        }
    }

    const onSave = async () => {

        setCheckRule(true);
        let vRule = true;

        if (!data.name
            || !data.body) {
            vRule = false;
        }

        if (vRule) {

            let tData = { ...data };

            if (rowIndex < 0) {
                tData.id = props.id;
                tData.created = moment().format("YYYY.MM.DD HH:mm:ss");
            }
            if (props.onAfterSave) {
                props.onAfterSave(tData);
            }
            onDismiss();
        }
    }

    const onDismiss = () => {
        setCheckRule(false);
        setData({ ...initData });
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    return (
        <div>
            <PanelBase
                delete
                onDelete={() => props.onDelete(props.id)}
                isOpen={props.isOpen}
                onDismiss={onDismiss}
                headerText={rowIndex >= 0 ? "Үзүүлэлт засах" : "Үзүүлэлт нэмэх"}
                buttons={(
                    <ButtonDefault onClick={onSave} text="Хадгалах" />
                )}
            >
                <div style={{ marginTop: 13 }}>
                    <FieldText required title="Үзүүлэлтийн нэр" onChanged={fieldOnChanged} keyfield='name' defaultValue={data.name} checkreq={checkRule} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldText required title="Үзүүлэлт" onChanged={fieldOnChanged} keyfield='body' defaultValue={data.body} checkreq={checkRule} />
                </div>
            </PanelBase>
        </div>
    );
}

export default SpecificationsNew;