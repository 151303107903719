import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { firebaseApp } from '../../../../API/firebaseApp';
import { getDatabase, ref, push, get, set } from "firebase/database";
import { message } from 'antd';
import { Breadcrumb } from 'antd';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Col, Row } from 'antd';
import NewsImage from './NewsImage';
import LoadSpin from '../../../../Components/Base/LoadSpin/LoadSpin';
import ButtonDefault from '../../../../Components/Base/ButtonDefault/ButtonDefault';
import FieldCheck from '../../../../Components/Base/FieldCheck/FieldCheck';
import FieldDropdown from '../../../../Components/Base/FieldDropdown/FieldDropdown';
import FieldText from '../../../../Components/Base/FieldText/FieldText';
import FieldTextArea from '../../../../Components/Base/FieldTextArea/FieldTextArea';

const NewsNew = (props) => {

    const params = useParams();
    const navigate = useNavigate();

    const [initData, setInitData] = useState({
        title: null,
        bodymid: null,
        body: null,
        visitcount: 0,
        img: null,
        images: [],
        active: true,
        created: moment().format("YYYY.MM.DD HH:mm:ss")
    });

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [checkRule, setCheckRule] = useState(false);
    const [dCategory, setDCategory] = useState([
        {
            key: 1,
            text: "Мэдээлэл"
        },
        {
            key: 2,
            text: "Зөвлөгөө"
        }
    ]);

    const getData = async () => {
        let tData = { ...initData };
        setLoading(true);
        if (params.id) {
            await get(ref(getDatabase(firebaseApp), "news/" + params.id)).then(response => {
                let resp = response.toJSON();
                if (resp) {
                    tData = resp;
                    let tImages = [];
                    if (tData?.images) {
                        Object.keys(tData.images)?.map(key => {
                            tImages.push(tData.images[key]);
                        });
                    }
                    tData.images = tImages;
                }
            });
        }

        setLoading(false);
        setData(tData);
        setCheckRule(false);
    }

    useEffect(() => {
        getData();
    }, [params.id]);

    const fieldOnChanged = async (key, text, field, fieldname) => {
        if (field) {
            let tData = { ...data };
            tData[field] = key;
            if (fieldname) {
                tData[fieldname] = text;
            }
            setData(tData);
        }
    }

    const onSave = async () => {

        let tData = { ...data };
        setCheckRule(true);
        let success = false;
        let tId = "";

        let vRule = true;

        if (!tData.title
            || !tData.categoryid) {
            vRule = false;
        }

        if (vRule) {
            setLoadingSave(true);

            if (tData?.images?.length > 0) {
                tData.img = tData?.images[0].img;
            }

            if (params.id && params.id != -1) {
                await set(ref(getDatabase(firebaseApp), "news/" + params.id), tData).then(response => {
                    success = true;
                    message.success("Амжилттай.");
                });
            } else {
                tData.created = moment().format("YYYY.MM.DD HH:mm:ss");
                await push(ref(getDatabase(firebaseApp), "news"), tData).then(response => {
                    tId = response.key;
                    success = true;
                    message.success("Амжилттай.");
                });
            }
            setLoadingSave(false);
            if (success) {
                navigate("/admin/news");
            }
        }
    }

    return (
        <div style={{ padding: 16 }}>
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/admin/news">Зөвлөгөө, мэдээллийн жагсаалт</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {params.id}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div style={{ marginTop: 24, fontWeight: 600, fontSize: 20 }}>
                Зөвлөгөө, мэдээллийн дэлгэрэнгүй
            </div>
            <div style={{ marginTop: 21 }}>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <FieldText required loading={loading} title="Зөвлөгөө мэдээллийн нэр" onChanged={fieldOnChanged} keyfield='title' defaultValue={data.title} checkreq={checkRule} />
                    </Col>
                    <Col span={6}>
                        <FieldDropdown required loading={loading} title="Категори" onChanged={fieldOnChanged} keyfield='categoryid' keyfieldname='categoryname' defaultValue={data.categoryid} data={dCategory} checkreq={checkRule} />
                    </Col>
                    <Col span={6}>
                        <FieldCheck loading={loading} title="Төлөв" onChanged={fieldOnChanged} keyfield='active' defaultValue={data.active} />
                    </Col>
                </Row>
            </div>
            <div style={{ marginTop: 16 }}>
                <FieldTextArea loading={loading} title="Богино тайлбар" onChanged={fieldOnChanged} keyfield='bodymid' defaultValue={data.bodymid} />
            </div>
            <div style={{ marginTop: 16 }}>
                <FieldTextArea loading={loading} title="Мэдээлэл" onChanged={fieldOnChanged} keyfield='body' defaultValue={data.body} />
            </div>

            <div style={{ marginTop: 16 }}>
                <NewsImage loading={loading} title="Зураг" onChanged={fieldOnChanged} keyfield='images' defaultValue={data.images} />
            </div>

            <div style={{ marginTop: 16 }}>
                {loadingSave ? (
                    <LoadSpin />
                ) : (
                    <ButtonDefault onClick={onSave} text="Хадгалах" />
                )}
            </div>
        </div>
    );
}

export default NewsNew;