import React from 'react';

const Divider = (props) => {

    if (props.active) {
        return (
            <svg width="4" height="40" viewBox="0 0 4 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="4" height="40" fill="#F8A50C" />
            </svg>
        );
    } else {
        return (
            <div style={{ width: 4, height: 40 }}></div>
        );
    }
}

export default Divider;