import React from 'react';

const FieldLabel = (props) => {
    return (
        <div className='fontMid' style={{ fontWeight: 400, color: "#212121" }}>
            {props.value}
        </div>
    );
}

export default FieldLabel;