import React from 'react';
import DashboardIcon from '../../Svg/nav/DashboardIcon';
import Divider from '../../Svg/nav/Divider';
import UserIcon from '../../Svg/nav/UserIcon';
import OrderIcon from '../../Svg/nav/OrderIcon';
import ProductIcon from '../../Svg/nav/ProductIcon';
import NewsIcon from '../../Svg/nav/NewsIcon';
import CategoryIcon from '../../Svg/nav/CategoryIcon';
import UserInfo from '../../Svg/nav/UserInfo';
import OrderInfo from '../../Svg/nav/OrderInfo';
import Exit from '../../Svg/nav/Exit';
import TimelineIcon from '../../Svg/nav/TimelineIcon';

const MenuLabel = (props) => {

    let Icon = null;

    if (props.name == "dashboard") {
        Icon = <DashboardIcon active={props.name == props.current} />;
    } else if (props.name == "user") {
        Icon = <UserIcon active={props.name == props.current} />;
    } else if (props.name == "order") {
        Icon = <OrderIcon active={props.name == props.current} />;
    } else if (props.name == "product") {
        Icon = <ProductIcon active={props.name == props.current} />;
    } else if (props.name == "news") {
        Icon = <NewsIcon active={props.name == props.current} />;
    } else if (props.name == "category") {
        Icon = <CategoryIcon active={props.name == props.current} />;
    } else if (props.name == "dtl") {
        Icon = <UserInfo active={props.name == props.current} />;
    } else if (props.name == "orderinfo") {
        Icon = <OrderInfo active={props.name == props.current} />;
    } else if (props.name == "timeline") {
        Icon = <TimelineIcon active={props.name == props.current} />;
    } else if (props.name == "exit") {
        Icon = <Exit />;
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', height: 40 }}>
            <div style={{ height: 40 }}>
                <Divider active={props.name == props.current} />
            </div>
            <div style={{ width: 24, height: 24, marginLeft: 8 }}>
                {Icon}
            </div>
            <div style={{ height: 40, marginLeft: 8 }}>
                {props.title}
            </div>
        </div>
    );
}

export default MenuLabel;