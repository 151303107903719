import React from 'react';
import aboutUsCover from '../../Images/aboutUsCover.jpg';

const AboudUsIntro = (props) => {

    return (
        <div className='mainPadding' style={{ minHeight: 186, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundImage: 'url(' + aboutUsCover + ')', backgroundSize: 'cover', paddingTop: 32, paddingBottom: 32 }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: 24, color: '#FFFFFF' }}>
                    "ӨӨРӨӨ" гэж юу вэ?
                </div>
                <div style={{ marginTop: 16, fontWeight: 300, fontSize: 18, color: '#FFFFFF', maxWidth: '70%', textAlign: 'justify', }}>
                    Аливаа бүтээгдэхүүнүүд үйлдвэрээс гараад дэлгүүрт очин түрээсийн болон үйл ажиллагааны зардал, борлуулалтын ашиг нэмэгдсэн үнээр худалдаалагддаг. Бид ямар нэгэн дамжлагагүйгээр танд хэрэгцээт барааг үйлдвэрийн үнээр нь захиалж авах боломжийг олгож байна.
                </div>
            </div>
        </div>
    );
}

export default AboudUsIntro;