import React from 'react';

const Title = (props) => {
    return (
        <div className='fontTitle' style={{ fontWeight: 700, fontFamily: 'Montserrat', color: '#212121' }}>
            {props.value}
        </div>
    );
}

export default Title;