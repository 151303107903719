import React from 'react';

const UserAgreement = () => {
    return (
        <div style={{ padding: 16 }}>
            <div style={{ marginTop: 16 }}>
                <div style={{ fontWeight: 600, fontSize: 14 }}>
                    НЭГ. ЕРӨНХИЙ ЗҮЙЛ
                </div>
                <div style={{ marginLeft: 16 }}>
                    <div style={{ marginTop: 8 }}>
                        1.1 Энэхүү журмаар Өөрөө эм эн ХХК-ийн (цаашид Вэбсайт гэнэ) захиалгат худалдааны үйлчилгээ, түүнтэй холбоотой үүсэх бүх төрлийн харилцааг зохицуулна.
                    </div>
                    <div style={{ marginTop: 4 }}>
                        1.2 Вэбсайт нь олон улсад байрлалтай онлайн болон онлайн бус дэлгүүр, нийлүүлэгчээс хэрэглэгчийн сонгон захиалсан бараа бүтээгдэхүүнийг худалдан авч нийлүүлэх үйлчилгээ үзүүлнэ.
                    </div>
                    <div style={{ marginTop: 4 }}>
                        1.3 Энэхүү журмын хэрэгжилтэд Вэбсайт болон үйлчлүүлэгч хамтран хяналт тавина.
                    </div>
                </div>
            </div>

            <div style={{ marginTop: 16 }}>
                <div style={{ fontWeight: 600, fontSize: 14 }}>
                    ХОЁР. ЗАХИАЛГА
                </div>
                <div style={{ marginLeft: 16 }}>
                    <div style={{ marginTop: 8 }}>
                        2.1 Вэбсайт нь ОУ-ын үйл ажиллагаа эрхэлдэг онлайн болон онлайн бус дэлгүүрүүд, нийлүүлэгчдээс хэрэглэгчийн сонгон захиалсан бараа бүтээгдэхүүнийг худалдан авч нийлүүлэн үйлчилнэ.
                    </div>
                    <div style={{ marginTop: 4 }}>
                        2.2 Захиалгын үйлчилгээ нь www.uuruu.mn захиалгат худалдааны нэгдсэн системээр захиалах бөгөөд хэрэглэгч захиалга өгснөөс эхлэн худалдан авалт, тээвэрлэлт, төлбөр төлөлт, барааг хүлээлгэн өгөх хүртэлх бүх үйл явц энэ системээр удирдагдана.
                    </div>
                    <div style={{ marginTop: 4 }}>
                        2.3 Захиалгын үнийг хэрэглэгч зөвшөөрөн баталгаажуулж, төлбөр хийгдсэнээс хойш 3 цагийн дотор төлбөр баталгаажих ба захиалга төлбөр төлсөн гэсэн төлөвтэй болсон үед автоматаар илгээгдэнэ. Хэрэглэгч төлбөр төлсний дараа Вэбсайт дах Миний захиалгууд хэсэгрүү орж захиалгын төлөвийг хянана.
                    </div>
                    <div style={{ marginTop: 4 }}>
                        2.4 Бараа Улаанбаатар хотод ирсний дараа захиалагч өөрийн биеэр болон албан ёсны төлөөлөгчөө илгээж оффисоос ирж авах, хүргэлтээр болон хөдөө орон нутагруу унаанд тавиулж авах боломжтой. Барааг хүргэлтээр болон унаанд тавьснаас хойших бүх үйл явцыг захиалагч өөрөө хариуцна.
                    </div>
                </div>
            </div>

            <div style={{ marginTop: 16 }}>
                <div style={{ fontWeight: 600, fontSize: 14 }}>
                    ГУРАВ. ҮНЭ
                </div>
                <div style={{ marginLeft: 16 }}>
                    <div style={{ marginTop: 8 }}>
                        3.1 Захиалгын үнийг Вэбсайтын захиалгын систем тооцоолон гаргана.
                    </div>
                    <div style={{ marginTop: 4 }}>
                        3.2 Захиалгын үнэнд дараах зүйлсийг оруулан тооцох бөгөөд Монголд ирсэн барааны бодит жингээс хамааран тээвэрлэлтийн үнийн дүн өөрчлөгдөж болно.
                    </div>
                    <div style={{ marginTop: 4 }}>
                        Үүнд:
                        <div>
                            • Барааны үнэ
                        </div>
                        <div>
                            • Тухайн барааны Монголруу илгээгдэх карго хүртэлх хүргэлтийн үнэ
                        </div>
                        <div>
                            • Улаанбаатар хотруу тээвэрлэх үнэ
                        </div>
                        <div>
                            • Монгол улсын гаалийн татвар (Монгол улсын гаалийн татварын дагуу)
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ marginTop: 16 }}>
                <div style={{ fontWeight: 600, fontSize: 14 }}>
                    ДӨРӨВ. ТӨЛБӨР
                </div>
                <div style={{ marginTop: 4 }}>
                    4.1 Хэрэглэгч бараа захиалгын үнийн 100 хувийг төлснөөр захиалгыг баталгаажсан гэж үзэн худалдан авалтыг хийнэ.
                </div>
                <div style={{ marginTop: 4 }}>
                    4.2 Төлбөрийг бэлнээр болон дансаар төлөх боломжтой.
                </div>
            </div>

            <div style={{ marginTop: 16 }}>
                <div style={{ fontWeight: 600, fontSize: 14 }}>
                    ТАВ. ХҮРГЭЛТ
                </div>
                <div style={{ marginLeft: 16 }}>
                    <div style={{ marginTop: 4 }}>
                        6.1 Вэбсайт хэрэглэгчийн хүсэлд нийцүүлэн нийслэл хот, хөдөө орон нутагт захиалгын бараа бүтээгдэхүүнийг хүргэх үйлчилгээ үзүүлнэ.
                    </div>
                    <div style={{ marginTop: 4 }}>
                        6.2 Хүргэлтийн үйлчилгээ авах эсэхийг хэрэглэгч захиалга хийхдээ эсвэл УБ-т ирсний дараа мэдэгдэх бөгөөд хүргүүлэх газрын байрлалаас шалтгаалан хүргэлтийн үнэ тогтоогдож, тээврийн зардал дээр нэмж тооцогдоно.
                    </div>
                    <div style={{ marginTop: 4 }}>
                        6.3 Улаанбаатар хотод хүргэлтийн үйлчилгээг бүсчилсэн үнийн тарифаар гүйцэтгэнэ.
                    </div>
                    <div style={{ marginTop: 4 }}>
                        6.4 Орон нутгийн хүргэлтийг хот хоорондын тээврийн үйлчилгээний байгууллагуудтай хамтран гүйцэтгэнэ.
                    </div>
                </div>
            </div>

            <div style={{ marginTop: 16 }}>
                <div style={{ fontWeight: 600, fontSize: 14 }}>
                    ДОЛОО. БАРАА БУЦААЛТ
                </div>
                <div style={{ marginLeft: 16 }}>
                    <div style={{ marginTop: 4 }}>
                        7.1 Олон улс дахь дэлгүүр, нийлүүлэгчийн бараа буцаах хугацаа, журамд нийцүүлэн хэрэглэгчийн хүсэлтээр Монголд ирсэн бараа (размер өнгө буруу, ажиллахгүй, хадгалалтын хугацаа дууссан, тээвэрлэлтийн явцад гэмтсэн,) бүтээгдэхүүнийг үнэгүй буцааж болох ба бусад тохиолдолд uuruu.mn-н тухайн үеийн ханшаар буцааж болно. Бараа бүтээгдэхүүнийг худалдан авсан дэлгүүрт буцаан очих хугацаа нь тухайн дэлгүүрийн бараа буцаах журамд заагдсан хугацаанд багтсан байх ёстой.
                    </div>
                    <div style={{ marginTop: 4 }}>
                        7.2 Бараа бүтээгдэхүүнийг буцаахтай холбогдон гарах тээвэрлэлт, торгууль зэрэг бүх зардлыг хүсэлт гаргасан хэрэглэгч хариуцна.
                    </div>
                    <div style={{ marginTop: 4 }}>
                        7.3 Буцаагдсан бараанд ноогдох тээвэрлэлт, үйлчилгээний хөлс, бусад зардал нь буцаан олгогдохгүй ба барааны үндсэн үнээс нийлүүлэгч талаас нэхэмжилж болох зардлыг хассан дүнгээр нийлүүлэгчээс барааны төлбөр бүрэн буцан шилжиж орсон тохиолдолд хэрэглэгчид олгоно.
                    </div>
                </div>
            </div>

            <div style={{ marginTop: 16 }}>
                <div style={{ fontWeight: 600, fontSize: 14 }}>
                    НАЙМ. БУСАД
                </div>
                <div style={{ marginLeft: 16 }}>
                    <div style={{ marginTop: 4 }}>
                        11.1 Захиалгын бараа бүтээгдэхүүн дууссан, ямар нэгэн байдлаар захиалга цуцлагдсан (барааны нөөц дуусах, тухайн дэлгүүр захиалгын доод болон дээд лимиттэй, тээврийн зардал нэхэх, түүнчлэн тухайн дэлгүүр журмын дагуу 76 цагийн дотор илгээх ёстой боловч илгээгээгүй удсан мөн илгээсэн ч хятад карго дотроо алга болсон) тохиолдолд хэрэглэгч захиалгыг өөр бүтээгдэхүүнээр солих, нэмэлт төлбөр төлөх эсвэл тухайн барааны захиалгыг цуцалж мөнгөө 100% буцаан авч болно.
                    </div>
                    <div style={{ marginTop: 4 }}>
                        11.2 Захиалгыг цуцалсан тохиолдолд дууссан бараа бүтээгдэхүүний үнийг хасан захиалгын нийт үнэ бодогдоно.
                    </div>
                    <div style={{ marginTop: 4 }}>
                        11.3 Тухайн нөхцөлд дууссан боловч тодорхой хугацааны дараа тухайн бараа онлайн дэлгүүрт ирэхийг мэдэгдсэн тохиолдолд худалдан авалтыг үргэлжлүүлэх эсэхийг хэрэглэгчийн хүсэлтээр шийдвэрлэнэ.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserAgreement;