import { Breadcrumb, Col, Row } from 'antd';
import { equalTo, get, getDatabase, orderByChild, query, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { firebaseApp } from '../../../API/firebaseApp';
import Title from '../../../Components/Title/Title';
import Number from '../../../Components/Base/Number/Number';
import FieldText from '../../../Components/Base/FieldText/FieldText';
import GridDefaultShimmer from '../../../Components/Base/GridDefaultShimmer/GridDefaultShimmer';

const UserDtl = (props) => {

    const params = useParams();
    const [data, setData] = useState({});
    const [dataOrder, setDataOrder] = useState([]);
    const [oDataOrder, setODataOrder] = useState([]);
    const [loading, setLoading] = useState(false);

    const [columns, setColumns] = useState([
        {
            name: 'Захиалгын №',
            fieldName: 'refno',
            minColSize: 'small'
        }, {
            name: 'Захиалсан огноо',
            fieldName: 'created',
            minColSize: 'small'
        }, {
            name: 'Төлөв',
            fieldName: 'statusname',
            minColSize: 'mid'
        }, {
            name: 'Хэрэглэгч',
            fieldName: 'phone',
            minColSize: 'small'
        }, {
            name: 'Хүргэлт',
            fieldName: 'delivery',
            minColSize: 'small',
            onRender: (item) => {
                if (item.delivery > 0) {
                    return (
                        <div>Хүргэлтээр</div>
                    );
                } else {
                    return (
                        <div>Салбараас очиж авах</div>
                    );
                }
            }
        }, {
            name: 'Хаяг',
            fieldName: 'address',
            minColSize: 'small'
        }, {
            name: 'Тоо, ширхэг',
            fieldName: 'qty',
            minColSize: 'small',
            onRender: (item) => {
                let vQty = 0;
                if (item?.item?.length > 0) {
                    item.item.map(r => {
                        if (!isNaN(r.qty)) {
                            vQty += parseInt(r.qty);
                        }
                    });
                }
                return (
                    <Number value={vQty} />
                );
            }
        }, {
            name: 'Нийт дүн',
            fieldName: 'price',
            onRender: (item) => {
                let vPrice = 0;
                if (item?.item?.length > 0) {
                    item.item.map(r => {
                        if (!isNaN(r.qty) && !isNaN(r.price)) {
                            vPrice += parseInt(r.qty) * parseFloat(r.price);
                        }
                    });
                }
                return (
                    <Number value={vPrice} csymbol="₮" />
                );
            }
        }
    ]);

    const getData = async () => {

        let tDataOrder = [];
        let tData = {};

        if (params?.id) {
            setLoading(true);

            await get(ref(getDatabase(firebaseApp), "user/" + params.id)).then(response => {
                let resp = response.toJSON();
                if (resp) {
                    tData = resp;
                }
            });

            if (tData?.uid) {
                await get(query(ref(getDatabase(firebaseApp), "order"), orderByChild("uid"), equalTo(tData?.uid))).then(response => {
                    let resp = response.toJSON();
                    if (resp) {
                        Object.keys(resp)?.map(key => {
                            let tItem = [];
                            if (resp[key].item) {
                                Object.keys(resp[key].item)?.map(itemkey => {
                                    tItem.push(resp[key].item[itemkey]);
                                });
                            }
                            tDataOrder.push({ orderid: key, ...resp[key], item: tItem });
                        });
                    }
                });
            }

            setLoading(false);
        }

        tDataOrder?.sort((a, b) => {
            if (a.created > b.created) {
                return -1;
            }
            if (a.created < b.created) {
                return 1;
            }
            return 0;
        });

        setDataOrder(tDataOrder);
        setODataOrder(tDataOrder);
        setData(tData);
    }

    useEffect(() => {
        getData();
    }, [params?.id]);

    const onSearch = (p_data) => {
        setODataOrder(p_data);
    }

    return (
        <div style={{ margin: 16 }}>
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/admin/user">Хэрэглэгчийн жагсаалт</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {data?.phone}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div style={{ marginBottom: 22, marginTop: 16 }}>
                <Title value="Хэрэглэгчийн дэлгэрэнгүй" />
                <div style={{ marginTop: 21 }}>
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <FieldText title="Овог" disable={true} defaultValue={data?.lastname} />
                        </Col>
                        <Col span={6}>
                            <FieldText title="Нэр" disable={true} defaultValue={data?.firstname} />
                        </Col>
                        <Col span={6}>
                            <FieldText title="Утасны дугаар" disable={true} defaultValue={data?.phone} />
                        </Col>
                        <Col span={6}>
                            <FieldText title="И-мэйл хаяг" disable={true} defaultValue={data?.email} />
                        </Col>
                    </Row>
                </div>
            </div>
            <Title value="Захиалгууд" />
            <GridDefaultShimmer
                data={dataOrder}
                columns={columns}
                loading={loading}
            />
        </div>
    );
}

export default UserDtl;