import React from 'react';

const ButtonBase = (props) => {

    let styles = {
        container: {

        },
        text: {

        }
    };

    if (props.second) {
        styles = {
            container: {
                background: '#FFFFFF'
            },
            text: {
                color: '#212121'
            }
        };
    }

    return (
        <div onClick={props.onClick}
            style={{
                height: 48,
                paddingRight: 16,
                paddingLeft: 16,
                background: props.color ? props.color : '#F8A50C',
                borderRadius: 24,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                ...styles.container
            }}>
            {props.icon ? <div style={{ marginRight: 8, marginTop: 4 }}>{props.icon}</div> : null}
            <div style={{
                fontWeight: 500,
                fontSize: 16,
                color: '#FFFFFF',
                ...styles.text
            }}>
                {props.text}
            </div>
        </div>
    );
}

export default ButtonBase;