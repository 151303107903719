import React, { useEffect, useState, useContext } from 'react';
import { ReactComponent as Logo } from '../../../Svg/logoWhite.svg';
import { Persona, PersonaSize } from '@fluentui/react';
import { Menu } from 'antd';
import MenuLabel from '../../../Components/MenuLabel/MenuLabel';
import LogoutIcon from '../../../Svg/nav/LogoutIcon';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Category from '../Category/Category';
import Product from '../Product/Product';
import ProductNew from '../Product/New/ProductNew';
import { AdminAuthContext } from '../../../Context/AdminAuthContext';
import Order from '../Order/Order';
import OrderDtl from '../OrderDtl/OrderDtl';
import User from '../User/User';
import UserDtl from '../UserDtl/UserDtl';
import News from '../News/News';
import NewsNew from '../News/New/NewsNew';
import Dashboard from '../Dashboard/Dashboard';

const AdminMain = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const { adminAuth, setAdminAuth } = useContext(AdminAuthContext);
    const [currentMenu, setCurrentMenu] = useState("dashboard");

    useEffect(() => {
        if (location?.pathname) {
            if (location.pathname.split("/").length > 2) {
                setCurrentMenu(location.pathname.split("/")[2]);
            }
        }
    }, []);

    const onChangeMenu = (p_value) => {
        setCurrentMenu(p_value.key);
        navigate("/admin/" + p_value.key);
    }

    const onLogout = () => {
        setAdminAuth({});
        localStorage.removeItem("admin");
        navigate("/admin");
    }

    return (
        <div style={{ height: '100vh', width: '100wh', display: 'flex' }}>
            <div style={{ width: 237, height: '100%', background: '#233153', display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 32 }}>
                    <div style={{ width: 130, height: 34 }}>
                        <Logo />
                    </div>
                </div>
                <div style={{ flex: 1, marginTop: 30 }}>
                    <Menu style={{ background: '#233153', border: '1px solid #233153' }} onClick={onChangeMenu} defaultSelectedKeys={[currentMenu]} items={[{
                        key: "dashboard",
                        label: (<MenuLabel name="dashboard" current={currentMenu} title="Хянах самбар" />)
                    }, {
                        key: "user",
                        label: (<MenuLabel name="user" current={currentMenu} title="Хэрэглэгч" />)
                    }, {
                        key: "order",
                        label: (<MenuLabel name="order" current={currentMenu} title="Захиалга" />)
                    }, {
                        key: "product",
                        label: (<MenuLabel name="product" current={currentMenu} title="Бүтээгдэхүүн" />)
                    }, {
                        key: "news",
                        label: (<MenuLabel name="news" current={currentMenu} title="Заавар зөвлөгөө" />)
                    }, {
                        key: "category",
                        label: (<MenuLabel name="category" current={currentMenu} title="Категори" />)
                    }]} />
                </div>
                <div>
                    <div onClick={onLogout} style={{ margin: 16, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <LogoutIcon />
                        <div style={{ marginLeft: 8, fontWeight: 400, fontSize: 14, color: "#FFFFFF" }}>Гарах</div>
                    </div>
                </div>
            </div>
            <div style={{ flex: 1 }}>
                <div style={{ height: 56, width: '100%', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <div style={{ margin: 16 }}>
                        <Persona hidePersonaDetails text={adminAuth?.email ? adminAuth?.email : "Admin"} size={PersonaSize.size32} />
                    </div>
                </div>
                <div style={{ marginTop: 4 }}>
                    <Routes>
                        <Route path='/dashboard' element={<Dashboard />} />
                        <Route path='/product/:id' element={<ProductNew />} />
                        <Route path='/product' element={<Product />} />
                        <Route path='/category' element={<Category />} />
                        <Route path='/order' element={<Order />} />
                        <Route path='/order/:id' element={<OrderDtl />} />
                        <Route path='/user' element={<User />} />
                        <Route path='/user/:id' element={<UserDtl />} />
                        <Route path='/news' element={<News />} />
                        <Route path='/news/:id' element={<NewsNew />} />
                    </Routes>
                </div>
            </div>
        </div >
    );
}

export default AdminMain;