import React from 'react';
import { ReactComponent as Facebook } from '../../Svg/facebook.svg';
import { ReactComponent as Instagram } from '.././../Svg/instagram.svg';
import moment from 'moment';

const Footer = (props) => {

    const onPressLink = (pUrl) => {
        window.open(pUrl, '_blank');
    }

    return (
        <div className='mainPadding' style={{ height: 40, background: '#171D29', display: 'flex', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ fontWeight: 600, fontSize: 14, color: '#FFFFFF' }}>
                All Rights Reserved @{moment().format("YYYY")}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div onClick={() => onPressLink("https://www.facebook.com/uuruumn/")} style={{ cursor: 'pointer', width: 22, height: 22 }}>
                    <Facebook />
                </div>
                <div onClick={() => onPressLink("https://www.instagram.com/uuruu.mn/")} style={{ marginLeft: 16, cursor: 'pointer', width: 22, height: 22, marginTop: 1 }}>
                    <Instagram />
                </div>
            </div>
        </div>
    );
}

export default Footer;