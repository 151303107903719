import React from 'react';

const LogoutIcon = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 20.4C15.3313 20.4 15.6 20.1313 15.6 19.8C15.6 19.4687 15.3313 19.2 15 19.2H7.19998C5.87449 19.2 4.79998 18.1255 4.79998 16.8V7.20001C4.79998 5.87452 5.87449 4.80001 7.19998 4.80001H15C15.3313 4.80001 15.6 4.53137 15.6 4.20001C15.6 3.86864 15.3313 3.60001 15 3.60001H7.19998C5.21176 3.60001 3.59998 5.21179 3.59998 7.20001V16.8C3.59998 18.7883 5.21176 20.4 7.19998 20.4H15ZM16.3757 7.37575C16.61 7.14142 16.9899 7.14142 17.2243 7.37575L21.4243 11.5757C21.6585 11.8101 21.6585 12.19 21.4243 12.4243L17.2243 16.6243C16.9899 16.8586 16.61 16.8586 16.3757 16.6243C16.1414 16.39 16.1414 16.01 16.3757 15.7757L19.5515 12.6H8.99998C8.66861 12.6 8.39998 12.3313 8.39998 12C8.39998 11.6686 8.66861 11.4 8.99998 11.4H19.5515L16.3757 8.22427C16.1414 7.98995 16.1414 7.61006 16.3757 7.37575Z" fill="white" />
        </svg>
    );
}

export default LogoutIcon;