import React, { useEffect, useState } from 'react';
import loginBack from '../../Images/loginBack.jpg';
import { message, Tabs } from 'antd';
import ButtonBase from '../../Components/ButtonBase/ButtonBase';
import FieldNumber from '../../Components/FieldNumber/FieldNumber';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier, FacebookAuthProvider, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { firebaseApp } from '../../API/firebaseApp';
import OtpInput from 'react-otp-input';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { checkUserAuth } from '../../Const/SharedFunc';
import { get, getDatabase, ref, query, orderByChild, equalTo, push } from 'firebase/database';
import LoadSpin from '../../Components/Base/LoadSpin/LoadSpin';
import { ReactComponent as Facebook } from '../../Svg/facebook.svg';
import { ReactComponent as Google } from '../../Svg/google.svg';

const Login = (props) => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const auth = getAuth(firebaseApp);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [pageType, setPageType] = useState();

    const [loadingFB, setLoadingFB] = useState(false);
    const [loadingGoogle, setLoadingGoogle] = useState(false);

    useEffect(() => {
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'loginRecaptcha', {
            'size': 'invisible',
            'callback': (response) => {
            }
        });

        let tUserAuth = checkUserAuth();
        if (tUserAuth?.uid) {
            navigate("/userinfo/dtl");
        }
    }, []);

    const fieldOnChanged = async (key, text, field, fieldname) => {
        if (field) {
            let tData = { ...data };
            tData[field] = key;
            if (fieldname) {
                tData[fieldname] = text;
            }
            setData(tData);

            if (field == "otp" && key?.length == 6) {
                await onCheckOTP(key);
            }
        }
    }

    const onCheckOTP = async (pOtp) => {
        const confirmationResult = window.confirmationResult;
        if (confirmationResult) {
            try {
                setLoading(true);

                let success = false;
                let tUserAuth = {};
                await confirmationResult.confirm(pOtp).then((result) => {
                    if (result.user) {
                        success = true;
                        tUserAuth = result.user;
                    }
                }).catch((error) => {
                    message.warning("Таны оруулсан код буруу байна");
                });

                if (success) {
                    await onSuccess(tUserAuth);
                }
            } finally {
                setLoading(false);
            }
        } else {
            message.warning("Баталгаажуулах код илгээгээгүй байна");
        }
    }

    const onSuccess = async (pUserAuth) => {
        if (pUserAuth?.uid) {
            message.success("Амжилттай");
            localStorage.setItem("userAuth", JSON.stringify(pUserAuth));

            let tUserExists = false;
            await get(query(ref(getDatabase(firebaseApp), "user"), orderByChild("uid"), equalTo(pUserAuth?.uid))).then(response => {
                let resp = response.toJSON();
                if (resp) {
                    tUserExists = true;
                }
            });

            if (!tUserExists) {
                await push(ref(getDatabase(firebaseApp), "user"), {
                    displayname: pUserAuth?.displayName ?? null,
                    email: pUserAuth?.email ?? null,
                    photourl: pUserAuth?.photoURL ?? null,
                    phone: pUserAuth?.phoneNumber ?? null,
                    created: moment().format("YYYY.MM.DD HH:mm:ss"),
                    uid: pUserAuth?.uid
                }).then(response => { });
            }
            if (searchParams.get("callback") == "cart") {
                navigate("/cart");
            } else {
                navigate("/userinfo/dtl");
            }
        }
    }

    const onLogin = async () => {
        const appVerifier = window.recaptchaVerifier;
        if (data?.phone && appVerifier) {
            setLoading(true);
            await signInWithPhoneNumber(auth, "+976" + data.phone?.toString(), appVerifier)
                .then((confirmationResult) => {
                    setPageType("otp");
                    setLoading(false);
                    message.success("Баталгаажуулах код илгээлээ");
                    // SMS sent. Prompt user to type the code from the message, then sign the
                    // user in with confirmationResult.confirm(code).
                    window.confirmationResult = confirmationResult;
                    // ...
                }).catch((error) => {
                    message.warning("SMS илгээхэд алдаа гарлаа");
                    setLoading(false);
                });
        } else {
            message.warning("Утасны дугаар оруулна уу!");
        }
    }

    const onBackLogin = () => {
        setPageType();
    }

    const onFacebook = async () => {
        try {
            setLoadingFB(true);
            const provider = new FacebookAuthProvider();
            let tUserAuth = {};
            await signInWithPopup(auth, provider).then((result) => {
                tUserAuth = result?.user;
            }).catch((error) => {
                message.warning("Facebook-ээр нэвтрэхэд алдаа гарлаа");
            });
            if (tUserAuth) {
                await onSuccess(tUserAuth);
            }
        } finally {
            setLoadingFB(false);
        }
    }

    const onGoogle = async () => {
        try {
            setLoadingGoogle(true);
            const provider = new GoogleAuthProvider();
            let tUserAuth = {};

            await signInWithPopup(auth, provider)
                .then((result) => {
                    // The signed-in user info.
                    tUserAuth = result.user;
                }).catch((error) => {
                    message.warning("Google-ээр нэвтрэхэд алдаа гарлаа: " + error?.message ?? "");
                });

            if (tUserAuth) {
                await onSuccess(tUserAuth);
            }
        } finally {
            setLoadingGoogle(false);
        }
    }

    let vPanel = null;

    if (pageType == "otp") {
        vPanel = (
            loading ? (
                <LoadSpin />
            ) : (
                <div>
                    <div style={{
                        fontWeight: 400,
                        fontSize: 16,
                        color: '#212121',
                        textAlign: 'center'
                    }}>
                        Таны утсанд баталгаажуулах код илгээсэн
                    </div>
                    <div style={{ marginTop: 32 }}>
                        <OtpInput
                            inputStyle={{ width: 40, height: 40, borderRadius: 8, border: '1px solid #E7E7E7' }}
                            value={data.otp}
                            onChange={(otp) => fieldOnChanged(otp, "", "otp")}
                            numInputs={6}
                            renderSeparator={<span style={{ marginLeft: 8, marginRight: 8 }}>-</span>}
                            renderInput={(rprops) => <input {...rprops} />}
                        />
                    </div>
                    <div style={{ marginTop: 16 }}>
                        <ButtonBase second text={"Буцах"} onClick={onBackLogin} />
                    </div>
                </div>
            ));
    } else {
        vPanel = (
            <div>
                {/* <div style={{ marginTop: 16 }}>
                    {loadingFB ? <LoadSpin /> : <ButtonBase onClick={onFacebook} icon={<div style={{ width: 22, height: 22 }}><Facebook /></div>} color="#3b5998" text={"Facebook-ээр нэвтрэх"} />}
                </div> */}
                <div style={{ marginTop: 16 }}>
                    {loadingGoogle ? <LoadSpin /> : <ButtonBase onClick={onGoogle} icon={<div style={{ width: 22, height: 22 }}><Google /></div>} color="#4285F4" text={"Google-ээр нэвтрэх"} />}
                </div>
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 32 }}>
                    <div style={{ flex: 1, borderBottom: '1px solid #DADADA' }}></div>
                    <div style={{ fontSize: 12, color: 'rgb(118, 118, 118)', marginRight: 16, marginLeft: 16 }}>Эсвэл</div>
                    <div style={{ flex: 1, borderBottom: '1px solid #DADADA' }}></div>
                </div>
                <div style={{ marginTop: 24 }}>
                    <FieldNumber onChange={fieldOnChanged} title={"Утасны дугаар"} keyfield="phone" value={data.phone} />
                    <div style={{ marginTop: 32 }}>
                        {loading ? (
                            <LoadSpin />
                        ) : (
                            <div>
                                <ButtonBase text={"НЭВТРЭХ"} onClick={onLogin} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div style={{ padding: 65, backgroundImage: 'url(' + loginBack + ')', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className='login' style={{ width: 380, background: '#FFFFFF', boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.15)', borderRadius: 8, padding: 16 }}>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: "Нэвтрэх",
                            key: "1",
                            children: (
                                <div style={{ marginTop: 24 }}>
                                    {vPanel}
                                </div>
                            )
                        }
                    ]}
                />
            </div>
            <div id="loginRecaptcha"></div>
        </div>
    );
}

export default Login;