import React from 'react';

const ListHeader = (props) => {
    return (
        <div className='fontMidV2' style={{ fontWeight: 500, color: '#212121' }}>
            {props.value}
        </div>
    );
}

export default ListHeader;